import { theme } from '@/theme/theme';

export default function useStyles() {
	return {
		button: {
			color: theme.palette.secondary.contrastText,
			backgroundColor: theme.palette.glass.main,
			WebkitBackdropFilter: 'blur(5px)',
			backdropFilter: 'blur(5px)',
			borderRadius: '0.3rem',
			fontSize: '1.25rem',
			paddingInline: theme.spacing(2),
		},
		list: {
			color: theme.palette.secondary.contrastText,
			backgroundColor: theme.palette.secondary.main,
			boxShadow: '10px 10px 39px 0px rgba(0, 0, 0, 0.56)',
			webkitBoxShadow: '10px 10px 39px 0px rgba(0, 0, 0, 0.56)',
			mozBoxShadow: '10px 10px 39px 0px rgba(0, 0, 0, 0.56)',
			borderRadius: '0.3rem',
			'*': {
				color: theme.palette.secondary.contrastText,
				width: '100%',
				':hover': {
					color: theme.palette.primary.main,
				},
			},
		},
	};
}
