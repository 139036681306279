import puntarenasImg from '@images/Puntarenas.webp';
import naranjoImg from '@images/PlayaNaranjo.webp';

const isProd = import.meta.env.VITE_APP_ENV === 'PROD';

export const SEAT_ZONE_IDS = isProd
	? {
			Personas126: 1496,
			Vehículos126: 1497,
			Personas127: 1498,
			Vehículos127: 1499,
	  }
	: {
			Vehículos94: 1018,
			Personas94: 1019,
			Vehículos95: 1024,
			Personas95: 1023,
	  };

export const EVENT_ROUTES = isProd
	? {
			365: {
				origin: 'Puntarenas',
				destination: 'Playa Naranjo',
			},
			366: {
				origin: 'Playa Naranjo',
				destination: 'Puntarenas',
			},
	  }
	: {
			230: {
				origin: 'Puntarenas',
				destination: 'Playa Naranjo',
			},
	  };

export const FERRY_OPTIONS = isProd
	? [
			{
				origin: 'Puntarenas',
				destination: 'Playa Naranjo',
				id: 365,
				image: naranjoImg,
			},
			{
				origin: 'Playa Naranjo',
				destination: 'Puntarenas',
				id: 366,
				image: puntarenasImg,
			},
	  ]
	: [
			{
				origin: 'Puntarenas',
				destination: 'Playa Naranjo',
				id: 230,
				image: naranjoImg,
			},
	  ];
