import { useTheme } from '@mui/material';
import { keyframes } from '@mui/system';

export default function useStyles() {
	const theme = useTheme();

	// Define the pulsate animation using MUI's keyframes utility
	const pulsate = keyframes({
		'0%': {
			boxShadow: `0 0 0 0px ${theme.palette.primary.main}`,
		},
		'30%': {
			boxShadow: `0 0 0 10px rgba(0, 0, 0, 0)`,
		},
		'100%': {
			boxShadow: `0 0 0 0px rgba(0, 0, 0, 0)`,
		},
	});

	return {
		stepContainer: {
			display: 'flex',
			flexDirection: 'column',
			border: `2px solid transparent`,
			borderRadius: '1.3rem',
			padding: theme.spacing(3),
			minWidth: 321,
			backdropFilter: 'blur(20px)',
			gap: theme.spacing(2),
			boxShadow: theme.shadows[12],
			height: 'fit-content',
			background: theme.palette.glass.main,
		},
		stepContainerDisabled: {
			opacity: 0.5,
		},
		stepContainerActive: {
			border: `2px solid ${theme.palette.primary.main}`,
			animation: `${pulsate} 1.5s infinite ease-out`, // Use the pulsate animation
		},
	};
}
