import { SEAT_ZONE_IDS } from './data';

export const parsePayType = payType => {
	switch (+payType) {
		case 1:
			return 'datafono';
		case 2:
			return 'efectivo';
		case 3:
			return 'onlineb';
		default:
			return 'online';
	}
};

export const getTransactionType = transaction => {
	if (transaction.authorized === 0) return 'canceled';
	if (transaction.transactionStatus !== 'C' && transaction.status !== 'C')
		return 'declined';
	if (transaction.transactionType && transaction.transactionType !== 1)
		return 'gift';
	if (transaction.isPromo && transaction.isPromo !== 0) return 'promotion';
	return 'sale';
};

export const getProcessorType = processorId => {
	if (processorId === 7) return 'BINANCE';
	if (processorId === 8) return 'STRIPE';
	if (processorId === 9 || processorId === 10) return 'BNCR';
	return 'BAC';
};

export const generateRandomColors = () =>
	`#${Math.floor(Math.random() * 16777215).toString(16)}`;

export const regla3 = (total, needed) => (needed * total) / 100;

export const getZonePrices = zones => {
	const prices = [];
	zones?.forEach(s => {
		if (
			!prices.some(p => p.seatZoneDetail === s.seatZoneDetail) &&
			s.prices?.length !== 0 &&
			s.seatZoneAvailability !== 0
		) {
			prices.push({
				seatZoneDetail: s.seatZoneDetail,
				price: s.prices[0]?.amount,
				color: s.blockColor,
			});
		}
	});
	return prices;
};

export function getTransactionPrice(tickets) {
	let totalPrice = 0;

	for (const ticket of tickets) {
		totalPrice += ticket.ticketPrice;
	}

	return totalPrice;
}

export function isAnyQuantityGreaterThanZero(variantQuantities) {
	return Object.values(variantQuantities).some(variant => variant.quantity > 0);
}

export function parseVariantQuantities(variantQuantities, venueId) {
	return Object.values(variantQuantities)
		.filter(variant => variant.quantity > 0)
		.map(variant => ({
			PriceId: variant.id,
			Quantity: variant.quantity,
			SeatZoneId: SEAT_ZONE_IDS[`${variant.seatZoneName}${venueId}`],
		}));
}

export function getVehicles(variantQuantities) {
	const vehicles = [];
	Object.values(variantQuantities)
		.filter(variant => variant.seatZoneName === 'Vehículos')
		.forEach(variant => {
			for (let i = 0; i < variant.quantity; i++) {
				vehicles.push(variant.name);
			}
		});
	return vehicles;
}

export function getTotalVehicles(variantQuantities) {
	let total = 0;
	Object.values(variantQuantities)
		.filter(variant => variant.seatZoneName === 'Vehículos')
		.forEach(variant => {
			total += variant.quantity;
		});
	return total;
}

export function compareVehiclesDrivers(variantQuantities) {
	let totalVehicles = 0;
	let totalDrivers = 0;
	Object.values(variantQuantities).forEach(variant => {
		if (variant.seatZoneName === 'Vehículos') totalVehicles += variant.quantity;
		if (variant.id === 674 || variant.id === 676)
			totalDrivers += variant.quantity;
	});
	return totalDrivers >= totalVehicles;
}

export function findVehiclesSeatZoneId(seatZoneName, venueId) {
	return SEAT_ZONE_IDS[`${seatZoneName}${venueId}`];
}
