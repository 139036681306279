import { axiosInstance } from '..';
export const postSaveTransactionId = async (
	userId,
	eventDetailId,
	numberedSeats = null,
	nonNumberedSeats = null,
	products = null,
	influencerName = null,
	plates = null,
	token,
) => {
	const url = `/BuyTicket/SaveTransactionId`;

	const header = {
		headers: { Authorization: `Bearer ${token}` },
	};
	const res = await axiosInstance.post(
		url,
		{
			UserId: userId,
			EventDetailId: eventDetailId,
			BuyTicketMultipleSeatModels: numberedSeats,
			BuyTicketMultipleModels: nonNumberedSeats,
			BuyProductModels: products,
			InfluencerName: influencerName,
			AssignPlateModel: plates,
		},
		header,
	);
	return res.data;
};
