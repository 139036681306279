import { axiosInstance } from '..';
export const fetchTickets = async (userId, token) => {
	const url = `/UserOrderHistory/UserHistoryPerEventDetail?id=${userId}`;

	const header = {
		headers: { Authorization: `Bearer ${token}` },
	};

	const res = await axiosInstance.get(url, header);

	return res.data;
};
