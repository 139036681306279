const useCurrency = t => {
	const formatCurrency = (value, currency, validateZero = true) => {
		if (validateZero) {
			try {
				if (value === 0) return t('free');
			} catch (err) {
				// do nothing
			}
		}

		const formatted = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
			currencyDisplay: 'narrowSymbol',
			signDisplay: 'never',
		})
			.format(value)
			.replace('$', currency === 1 ? ' ₡' : ' $') // !Cambio aquí
			.replace('.00', '')
			.split(' ');

		const result = formatted[1] + formatted[0];

		return result.includes('NaN') ? 'ND' : result;
	};

	return formatCurrency;
};

export default useCurrency;
