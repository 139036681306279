const phrases = {
	labelHome: 'Home',
	labelAbout: 'About',
	labelContact: 'Contact',
	labelCategories: 'Categories',
	labelSignup: 'Sign Up',
	labelLogin: 'Sign In',
	'labelBanner Text': 'Lorem Ipsum is simply dummy text of the printing..',
	labelReadMore: 'See events',
	'labelSelect Category': 'Select Category',
	'labelSelect Venue': 'Select Venue',
	'labelSearch Here': 'Search Here',
	labelSearch: 'Search',
	upcomingEvents: 'Upcoming Events',
	labelViewMore: 'View More',
	labelEventCatgeory: 'Event Category',
	labelOtherEvents: 'Events',
	labelLatestPosts: 'Latest Posts',
	labelNewsLetter: 'News Letter',
	'labelFollow Us': 'Follow Us',
	labelCopyRight: 'ALL RIGHTS RESERVED',
	labelSeatAvailable: 'See available dates',
	labelTags: 'Tags',
	labelEvent1: 'Event1',
	labelEventDetail: 'Event detail',
	labelStartDate: 'Start Date',
	labelEndDate: 'End Date',
	labelVenue: 'Venue',
	labelAddress: 'Address',
	labelGetDirection: 'Get Direction',
	labelOrganizers: 'Organizers',
	labelSponsors: 'Sponsors',
	labelShare: 'Share',
	labelEventDetails: 'Be informed of all entertainment we have for you',
	labelAllConcerts: 'All Concerts',
	labelSeeTickets: 'Buy Tickets',
	labelSeeTicketsShort: 'Buy',
	labelLineUp: 'Line-Up',
	labelAddOns: 'Add-Ons',
	labelLoadMore: 'Load More',
	labelSubmit: 'Submit',
	labelNoOfTickets: 'No. Of Tickets',
	labelLowestPrice: 'Lowest Price',
	labelBestSeats: 'Best Seats',
	"labelDon'tMissEvents": "Don't miss our events...",
	labelEnterYourEmail: 'Enter Your Email',
	labelSubscribe: 'Subscribe',
	labelFirstName: 'First Name',
	firstNameRequired: 'First Name is Required',
	labelLastName: 'Last Name',
	lastNameRequired: 'Last Name is Required',
	labelEmailAddress: 'Email',
	labelEmailAddressC: 'Confirm Email',
	emailRequired: 'Email is required',
	emailReceiptRequired: "Receiver's email is required",
	phoneNumber: 'Phone Number',
	phoneRequired: 'Please fill your phone number',
	labelPassword: 'Password',
	passwordRequired: 'Password is Required',
	passwordLen: 'Contains 5 characters',
	passwordReg:
		'Password must contain at least one Uppercase, Digit and Special characters.',
	passwordMatch: "Passwords don't match",
	'labelConfirm Password': 'Confirm Password',
	labelNotifications: 'Notifications',
	labelSelectOption: 'Select your option',
	labelYes: 'Yes',
	labelNo: 'No',
	labelComments: 'Comments',
	labelTypeComments: 'Type your comments here..',
	labelAgree: 'I agree to',
	labelTerms: 'terms',
	labelAnd: 'and',
	labelPrivacy: 'privacy',
	labelMarkField: 'Please mark this fill',
	labelAlreadyHaveAccount: 'Already have an account?',
	labelHere: 'here',
	labelBackTo: 'Back to',
	labelBackPage: 'page',
	labelRememberMe: 'Remember me',
	'labelForgotPassword ?': 'Forgot password ?',
	labelOrLoginwith: 'Or Login with',
	"labelDon'tHaveAccount": "Don't have an account ?",
	labelDontThenSign: 'Sign up',
	labelForFree: 'for free',
	labelForgotPassword: 'Forgot Password',
	labelBacktoLogin: 'Back to Login',
	labelMyprofile: 'Profile',
	editProfile: 'Edit profile',
	userUpdated: 'User updated succesfully!',
	userNotUpdated: "Couldn't update information.",
	passwordUpdated: 'Password updated succesfully!',
	passwordNotUpdated: "Couldn't update password.",
	notifications: 'Notifications',
	notificationsDetail:
		'I wish to recieve information of future events and promotions via e-mail and SMS.',
	transactions: 'Transaction History',
	transfers: 'Transfer History',
	products: 'My Products',
	ProductsDesc: 'Access your available products.',
	TicketsDesc: 'Access your available tickets.',
	TransactionsDesc: 'Review your transactions to date.',
	TransfersDesc: 'Review your ticket transfers to date.',
	labelTicketNav: 'My Tickets',
	labelTicketResult: 'Download tickets',
	labelOnGoingEvents: 'On Going Events',
	labelLogout: 'Sign out',
	'labelD.0.B': 'D.0.B',
	labelGender: 'Gender',
	labelCurrentCity: 'Region',
	labelCountry: 'Country',
	labelZipCode: 'Zip Code',
	labelIdProof: 'Id Proof',
	'personal-information': 'Personal Information',
	PersonalInformationDesc: 'Review the information on your account.',
	labelContactInformation: 'Contact Information',
	labelUploadIdProof: 'ID Number',
	labelEventCategories: 'Event Categories',
	labelLocation: 'Location',
	labelOrganizerName: 'Organizer Name',
	labelPerformerName: 'Performer Name',
	labelOrganizerPhoneNo: 'Organizer Phone No',
	labelDay: 'Day',
	labelEventName: 'Event Name',
	labelSelectRange: 'Select Range',
	labelSeatNumber: 'Seat Number',
	labelSeatPrice: 'Seat Price',
	labelBuyTicket: 'Buy Tickets',
	labelGetTicket: 'Get Tickets',
	NoEmailFound: 'Email Not Found',
	EmailResetSuccess: 'Reset password link has been sent to your email id.',
	ForgotEmailRequired: 'You must provide your email address',
	SignAlreadyRegEmail: 'Email already registered.',
	SignAlreadyRegUserIdProof: 'Id already reagistered.',
	SignSuccess: 'Registered successfully!',
	InternalError: 'There was an error, please try again later',
	forgotLabelReset: 'Reset',
	forgotLabelForgot: 'Forgot',
	forgotLabelPassword: 'password?',
	forgotLabelDesc: 'It seems you forgot the password of your acccount.',
	forgotLabelExpiredInstruction:
		'Your URL has been Expired please send the request again for',
	forgotLabel1stDesc:
		"Type the email address you used when you joined and we'll send you the instructions.",
	labelNewPassword: 'New Password',
	labelPwdRequired: 'New password is required',
	userNoExist: 'User does not exist',
	usesOldPassword: 'You can not use the same password.',
	PasswordResetSuccess: 'Your password has been reset sucessfully',
	labelProvideValidEmail: 'Provide a valid email',
	labelSignIn: 'Sign In',
	userNotFound: 'User is not registered.',
	wrongPassword: 'Incorrect password.',
	labelDob: 'Date of birth',
	Male: 'Male',
	Female: 'Female',
	RatherNotSay: 'Rather not say',
	labelHi: 'Hi',
	labelInputToSearch: "You didn't provide any input to search",
	labelAddTicket: 'Add',
	labelSec: 'Sec',
	labelRow: 'Row',
	labelTable: 'Table',
	labelSeat: 'Seat',
	labelQuantity: 'Quantity',
	countryRequired: 'Please provide country',
	regionRequired: 'Please provide region',
	genderRequired: 'Please provide gender',
	dobRequired: 'Please provide date of birth',
	userIdProofRequired: 'Please provide ID Number',
	zipCodeRequired: 'Please provide your zipCode',
	invalidDob: 'Please provide a valid DOB',
	invalidFirstName: 'Please provide a valid First Name',
	invalidLastName: 'Please provide a valid Last Name',
	invalidGender: 'Please provide a valid Gender',
	invalidPhoneNo: 'Please provide a valid Phone Number',
	invalidZipCode: 'Please provide a valid Zip Code',
	invalidUserIdProof:
		'Please provide only alphanumerics characters from your ID',
	labelSave: 'Save',
	editSucess: 'Your profile was edit sucessfully',
	editFail: 'There was a problem editing your profile',
	labelSeatZone: 'Zone',
	labelTicketDetails: 'Ticket Details',
	labelTransactionDetails: 'Transaction Details',
	transactionDetails: 'Transaction details',
	labelClose: 'Close',
	labelTickets: 'Tickets',
	labelTransferTicket: 'Transfer',
	labelTransferTicketShort: 'Transfer tickets',
	transferTicketInfo:
		'Use this option to transfer your ticket to another registered person in specialticket.net',
	generateTicketInfo: 'Use this option to generate your ticket for the event',
	labelReceiptEmailAddress: 'Email address of the receiver',
	labelTicketTransferSuccess: 'The ticket was transfered sucessfully',
	ReceiverDoesntExist: 'The receiver user is not registered in the website',
	UnableToTransferTickets: "You're unable to transfer this ticket.",
	labelActions: 'Actions',
	labelGenerateQR: 'Generate QR',
	labelBack: 'Back',
	transferEmailRegistered: 'Email must be registered in specialticket.net.',
	labelSelect: 'Select all',
	labelTicket: 'Ticket',
	labelMustCompleteData: 'You must complete your profile information',
	labelForbidden: "You don't have access to see the requested resource",
	labelForbiddenMessage: 'Please login.',
	labelNotFound: 'Sorry, page not found',
	labelNotFoundMessage: "We weren't able to find the requested resource",
	userInactive: 'The user is inactive.',
	NewOwnerCompleteProfile: 'New owner must complete profile',
	labelVerify: 'Verify',
	labelAboutToTransfer: "You're about to transfer these tickets to:",
	labelSameEmail: 'You cannot transfer tickets to your own email.',
	NewOwnerInactive: 'User is unable to receive tickets.',
	userIdProofReg: 'The id is being used in another account.',
	labelNoGender: 'Prefer not to say',
	labelSearchResult: 'Search Results',
	labelSearchNotFound: 'No results found',
	MacOsError: 'Your device can not perform this action',
	labelDownloadTicket: 'Download Ticket',
	labelTotalPrice: 'Total Price: ',
	labelMyTickets: 'My Tickets',
	labelRecommendedSeats: 'Recommended Seats',
	labelLegend: 'Legend',
	labelAvailable: 'Available',
	labelReserved: 'Unavailable',
	labelSelected: 'Selected',
	labelMySeats: 'My seats',
	TransactionDetailForbidden:
		'There was an error checking this transaction detail',
	inProgress: 'In Progress',
	labelConfirmed: 'Confirmed',
	labelDeclined: 'Declined',
	labelTransStatus: 'Transaction status',
	labelLastCardBrandUsed: 'Payment method',
	labelProcessedTime: 'Payment datetime',
	labelTransactionSummary: 'Transaction Summary',
	labelTotalAmount: 'Total amount',
	labelNotNumbered: 'Not numbered',
	labelAvailability: 'tickets',
	labelThanksTransaction: 'Thanks for your transaction ... enjoy the event!',
	labelVenueName: 'Venue Name',
	labelContactNumber: 'Contact Number',
	labelState: 'State',
	labelCity: 'City',
	labelUploadImage: 'Upload Image',
	venueNameRequired: 'Venue Name Required',
	contactNumberRequired: 'Contact Number Required',
	stateRequired: 'State Required',
	cityRequired: 'City Required',
	addressRequired: 'Address Required',
	venueImageRequired: 'Venue Image Required',
	invalidEmail: 'Invalid email address',
	imageShouldSvg: 'The image has to be in SVG format',
	addVenue: 'Add Venue',
	save: 'Save',
	success: 'Success',
	labelZone: 'Zone',
	labelSeatZoneName: 'Zone',
	labelSeatZoneAvailability: 'Availability',
	labelSeatZoneType: 'Seat zone type',
	labelCapacity: 'Capacity',
	seatZoneNameRequired: 'Seat zone name Required',
	seatZoneavailabilityRequired: 'Seat zone availability Required',
	colorRequired: 'Color Required',
	venueRequired: 'Venue Required',
	seatZoneTypeRequired: 'Seat zone type Required',
	capacityRequired: 'Capacity Required',
	numerated: 'Numbered',
	noNumerated: 'Not Numbered',
	active: 'Active',
	labelEventCategoryName: 'Event category name',
	labelEventCategoryDetail: 'Event category detail',
	eventCategoryNameRequired: 'Event category name Required',
	eventCategoryDetailRequired: 'Event category detail Required',
	notAnImage: 'The file is not an image',
	imageRequired: 'The image is required',
	labelAddSponsors: 'Add Sponsors',
	labelSponsorName: 'Sponsor name',
	labelSponsorEmail: 'Sponsor email address',
	labelSponsorWebsite: 'Sponsor website',
	labelSponsorPhoneNumber: 'Sponsor phone number',
	labelSponsorLogo: 'Sponsor logo',
	sponsorNameRequired: 'Sponsor name Required',
	sponsorEmailRequired: 'Sponsor email address Required',
	sponsorWebsiteRequired: 'Sponsor website Required',
	sponsorPhoneNumberRequired: 'Sponsor phone number Required',
	sponsorLogoRequired: 'Sponsor logo Required',
	invalidUrl: 'Website is invalid',
	seatNumberRequired: 'Seat number Required',
	seatZoneRequired: 'Seat zone Required',
	rowRequired: 'Row Required',
	labelAddSeat: 'Add seats',
	performerNameRequired: 'Performer name Required',
	eventNameRequired: 'Event name Required',
	eventDetailsRequired: 'Event details Required',
	eventCategoryRequired: 'Event category Required',
	eventImageRequired: 'Event image Required',
	labelAddEvent: 'Add event',
	labelEventCategory: 'Event category',
	labelEventImage: 'Event image',
	labelEventCategoryImage: 'Event Category Image',
	event: 'Event',
	eventList: 'Event list',
	venueList: 'Venue list',
	zone: 'Zone',
	organizers: 'Organizers',
	sponsors: 'Sponsors',
	users: 'Users',
	seats: 'Seats',
	labelAddEventCategory: 'Add event category',
	labelEditEventCategory: 'Edit event category',
	labelAddEventDetails: 'Add event date',
	labelEditEventDetails: 'Edit event date',
	labelEventStartDate: 'Event start date',
	labelEventEndDate: 'Event end date',
	labelSalesStartDate: 'Sales start date',
	labelSalesEndDate: 'Sales end date',
	labelEventDatail: 'Event detail',
	labelMinutesForExpiration: 'Time for buying (mins)',
	eventStartDateRequired: 'Event start date required',
	eventEndDateRequired: 'Event end date required',
	salesStartDateRequired: 'Sales start date required',
	salesEndDateRequired: 'Sales end date required',
	eventRequired: 'Event required',
	minutesForExpirationRequired: 'Minutes for expiration required',
	eventDetailRequired: 'Event detail required',
	labelAdd: 'Add',
	sponsorRequired: 'Sponsor Required',
	labelAddDoor: 'Add door',
	labelDoor: 'Doors',
	labelDoorName: 'Door name',
	labelDoorAvailability: 'Door availability',
	doorNameRequired: 'Door name required',
	labelDelete: 'Delete',
	labelCurrentImage: 'Current Image',
	labelEventDate: 'Event date',
	labelSaleDate: 'Sale date',
	labelActiveToSell: 'Active to sell',
	labelActive: 'Active',
	labelInactive: 'Inactive',
	labelEmailMatch: 'Emails dont match',
	labelVATInformation: 'Billing Information',
	labelVATwho: 'Billing Name',
	labelVATemail: 'Billing Email',
	labelVATnumber: 'VAT Number',
	labelSeeRecommended: 'See Recommended',
	labelFunctionNum: 'Date ',
	labelGate: 'Gate',
	labelTicketSoon: 'Ticket Available Soon',
	labelFindBestEvents: 'The best entertainment at your hands',
	labelBannerText: 'Live the experience!',
	labelLoginText: 'Live the experience!',
	labelErrorPageTitle: 'Oops!',
	labelErrorPageText1: 'An error has ocurred.',
	labelErrorPageText2: 'Please try again.',
	labelTryAgain: 'Try again',
	labelUnableToBuyError:
		'The transaction could not be completed, please try again.',
	labelSeatsTakenError: 'Some seats are no longer available.',
	labelTo: 'to',
	Terms: 'Terms & Conditions',
	TermsDesc: 'Terms & Conditions of SpecialTicket.net.',
	'terms-and-conditions': 'Terms & Conditions',
	'terms-and-conditions-ferry': 'Terms & Conditions Coonatramar',
	'Terms&ConditionsDesc': 'Check here our Terms & Conditions.',
	'privacy-policy': 'Privacy Policy',
	PrivacyPolicyDesc: 'Check here our Privacy Policy.',
	EventTerms: 'Terms & Conditions for this event',
	labelMaxExceededError: 'Seat limit is ',
	labelZones: 'Zones & Prices',
	labelConfirm: 'Confirm',
	labelConfirmToContunue: 'Confirmation Required',
	labelWantToContinue: 'Do you want to continue with this operation?',
	'change-password': 'Change Password',
	ChangePasswordDesc: 'Change your current password.',
	labelAPassword: 'Actual password',
	labelCompleteForm: 'Must complete the form',
	successChangePassword: 'Password changed successfully',
	passChar: 'Includes special character',
	passNum: 'Includes number',
	passCapital: 'Includes capital letter',
	passLower: 'Includes lowercase letter',
	passMatch: 'Passwords match',
	oldpasswordnotmatch: 'Current password is not correcto',
	editVenue: 'Edit Venue',
	'Venue Already Exists': 'Venue Already Exists',
	'Venues Deleted successfully': 'Venues Deleted successfully',
	'Categories Deleted successfully': 'Categories Deleted successfully',
	'Events Deleted successfully': 'Events Deleted successfully',
	labelSeatZones: 'Zones',
	labelSeatZoneDetail: 'Zone Detail',
	blockColor: 'Block color',
	prices: 'Prices',
	pricesList: 'Prices list',
	taxesList: 'Taxes list',
	labelTaxName: 'Tax Name',
	labelTaxValue: 'Tax Value',
	labelFlat: 'Flat',
	labelPercent: 'Percent',
	FielRequired: 'Field is Required',
	labelTaxType: 'Tax Type',
	labelPriceDesc: 'Price Description',
	labelPriceAmount: 'Payment Amount',
	labelPriceFee: 'Service Fee',
	labelAddTax: 'Add Tax',
	labelEditTax: 'Edit Tax',
	selectedTaxes: 'Selected taxes',
	labelAmount: 'Amount',
	PriceDetail: 'Price Detail',
	ProductorPrice: 'Production price',
	ProductorTotal: 'Production total',
	labelPriceFeeTax: 'Service fee Tax',
	labelFinalAmount: 'Final Amount',
	labelNoTaxAmount: 'No tax amount',
	labelAddPrice: 'Add price',
	labelEditPrice: 'Edit price',
	NoUpdateTheZone: 'Unable to update the zone',
	SeatZoneExits: 'Seat Zone Already exists',
	labelEdit: 'Edit',
	labelEditSeats: 'See seats',
	labelSeatRow: 'Seat row',
	labelNumerate: 'Numerate ascendly',
	labelCheckerAssigned: 'Has checkers assigned',
	labelSeats: 'Seats',
	labelReserveTicket: 'Reserve tickets',
	labelNoRecommendations: 'No recommendations',
	labelCheckers: 'Checkers',
	labelCheckerName: 'Checker Name',
	labelChecker: 'Checker',
	completeRecaptcha: 'You must complete recaptcha.',
	recaptchaError: 'There was an error validating recaptcha.',
	labelGateName: 'Gate Name',
	labelAssignChecker: 'Assign Checker(s)',
	mustSelectChecker: 'Must Select at least one checker',
	labelCountFrom: 'Starting From',
	labelUserMaxQty: 'tickets per user',
	labelCabys: 'Cabys Code',
	eventEndDateMustBeGreater: 'Event end date must be greater than start date',
	eventSaleDateMustBeGreater:
		'Event sale end date must be greater than start date',
	labelZoneOp: 'Zone operations',
	labelSellQty: 'Selling quantity',
	labelAssignedPrices: 'Has an assigned price',
	labelDontHave: "Don't have any assigned",
	labelHasAssigned: 'Have assigned',
	labelAssignDoor: 'Assign door',
	labelAssignCoupons: 'Assign coupons',
	labelAssignPrice: 'Assign price',
	labelCoupons: 'Coupons',
	labelNoChanges: 'Must change to edit',
	mustSelectGate: 'Must select a door',
	mustSelectPrice: 'Must select a price',
	roleList: 'Role list',
	roleName: 'Role name',
	Dashboard: 'Dashboard',
	editSeats: 'Edit Seats',
	editEvent: 'Edit Event',
	editEventDetail: 'Edit Event Detail',
	labelPermissions: 'Permissions',
	accessToAllReports: 'Can see all events in reports',
	userLookup: 'Lookup',
	UserNoExists: 'User does not exist',
	userData: 'User Information',
	labelStatus: 'Status',
	upgradeRole: 'Role Change',
	Inactivate: 'Inactivate',
	Activate: 'Activate',
	reports: 'Reports',
	labelAssignBIN: 'Assign bins',
	labelBinsHere: 'Insert Bins here',
	labelBins: 'Bins',
	binInvalid: 'At least 1 bin is invalid',
	SellWithBins: 'Sell with bins',
	cardTransactions: 'Transactions',
	endDateMustBeGreater: 'end date must be greater than start date',
	labelCard: 'Card used',
	labelFunctionDate: 'Function Date',
	labelQuantityTickets: 'Tickets quantity',
	labelTransProccessedDate: 'Processed Date',
	labelTransactionType: 'Transaction Type',
	labelVenta: 'Sale',
	labelCortesia: 'Gift',
	labelTransactionReport: 'Transactions report',
	labelTransfers: 'Transfers',
	labelTransfersReport: 'Transfers report',
	NoData: "There's no data for selected filters",
	availableSeats: 'Availability',
	clean: 'Clear',
	MustSelectEvent: 'Must select an event',
	labelBlocked: 'Blocked',
	labelGifted: 'Gifted',
	labelReservedT: 'Reserved',
	labelSold: 'Sold',
	labelSales: 'Sales',
	labelAvailabilityReport: 'Zone Availability Report',
	consolidated: 'Consolidated sales',
	labelSelectByZone: 'Select by zone',
	labelColor: 'Color',
	labelName: 'Name',
	labelDetail: 'Detail',
	labelBlock: 'Block',
	labelUnblock: 'Unblock',
	labelGift: 'Gift',
	labelSell: 'Sell',
	labelGiftSuccess: 'Gift sent succesfully!',
	labelBlockSuccess: 'Seats blocked succesfully!',
	labelUnblockSuccess: 'Seats unblocked succesfully!',
	labelSeatsConflict: 'Verify the selected seats.',
	serverError: 'There was a server error.',
	unexpectedError: 'Oops! There was an unexpected error. Please try again.',
	labelSeeDetail: 'See Detail',
	labelTransactionDate: 'Transaction Date',
	labelTransferDate: 'Transfer Date',
	labelReciever: 'Reciever',
	labelAssignEvent: 'Assign Event',
	labelConsolidatedSales: 'Consolidated Sales',
	labelConsolidatedSalesReport: 'Consolidated Sales Report',
	labelConsolidatedSalesChart: 'Consolidated Sales Chart',
	labelPrice: 'Price',
	labelServiceFee: 'Service Fee',
	labelServiceFeeIv: 'Service Fee + Tax',
	operations: 'Operations',
	cancelSales: 'Cancel Sales/Gifts',
	labelCancelS: 'Cancel',
	labelCancelationReport: 'Cancelations',
	labelCanceledBy: 'Canceled By',
	labelCanceledTime: 'Canceled Date',
	mustLoginAgain: 'Must login to access the resource',
	seatManagement: 'Seat Management',
	labelDetails: 'Details',
	labelDates: 'Dates of the report',
	onGoingEvents: 'Your upcoming events',
	pricesTypes: 'Prices types',
	discountsList: 'Discounts Lists',
	labelAddDiscount: 'Add discount',
	labelValue: 'Value',
	labelType: 'Type',
	labelUse: 'Use',
	labelSingleTicket: 'Singular',
	labelMultipleTicket: 'Transaction',
	labelPriceTypeName: 'Price Type Name',
	labelEditPT: 'Edit price type',
	labelAddPT: 'Add price type',
	labelPriceType: 'Price Type',
	labelCurrency: 'Currency',
	labelEditPriceR:
		'When editing a price, if there are no tickets sold with the price it will be completely edited, otherwise it will be partially edited',
	chooseToDoSeats: 'Choose what to do with the seats.',
	seatManagementRoles: 'Seat Management Permissions',
	blockSeats: 'Block / unblock seats',
	giftSeats: 'Gift seats',
	sellSeats: 'Sell tickets',
	assignedPrices: 'assigned prices',
	assignedPrice: 'Assigned price',
	assignPriceType: 'Assign price type',
	labelMakeAvailable: 'Make seats available',
	labelQtyToM: 'Quantity to make available',
	labelQtyInfo: 'If leaving zero the total diff quantity will be available',
	terms1:
		'The use of the website specialticket.net, owned by Special Ticket Sale BV, S.A., is governed by the terms and conditions described below. Its use indicates full and unconditional acceptance thereof. By clicking the terms and conditions box on this website, you agree to all the terms and conditions detailed below. Special Ticket Sale BV, S.A., reserves the right to make changes to the site and the Terms and Conditions at any time without prior notice to the client.',
	terms2:
		'Special Ticket Sale BV, S.A. is a provider of a service, and therefore has no responsibility for any situation related to the event.',
	terms3:
		'Once accessed, the web page already indicates the buyer that he or she accepts the purchase conditions that are:',
	terms4:
		'Before making any purchase, please verify the event, date, area, number of seats you wish to purchase, as well as your email. Take into account that once the purchase is complete, changes, refunds, or returns are not authorized. In the case of events canceled for reasons beyond the control of SpecialTicket Sale BV SA, the purchaser of this ticket undertakes to make claims for a refund of money to the ORGANIZER OR RESPONSIBLE FOR THE EVENT, who will be exclusively responsible for the refund without any type of readjustment. Releasing Special Ticket Sale BV, S.A. from any type of responsibility.  ',
	terms5:
		'THE ORGANIZER OR RESPONSIBLE FOR THE EVENT reserves the right of admission, as well as the right not to authorize the entry of food, drinks, cameras or professional video cameras. The entry of firearms or weapons is not allowed.',
	terms6:
		'Special Ticket Sale BV, S.A and THE ORGANIZER OR RESPONSIBLE FOR THE EVENT are not responsible for eventual counterfeiting, or incorrect use of the digital ticket by resending or reselling the QR code.',
	terms7:
		'Special Ticket Sale BV, S.A. reserves the right of requesting the customers personal documentation necessary to validate their entry (national, residence or passport ID, card with which the transaction was made, email with the purchase).',
	terms8:
		"Special Ticket Sale BV, S.A., is authorized to use the personal data of ticket buyers to make commercial offers, and prospecting for their products. They are also authorized to  transfer the aforementioned data to its affiliated, subsidiary and related companies so that they can use this for commercial purposes. Special Ticket Sales BV, S.A., may deliver the client's personal data to the ORGANIZER OR RESPONSIBLE FOR THE EVENT.",
	policyTitle1: 'Collection of information',
	policyTitle2: 'Use of information',
	policyTitle3: 'Privacy of electronic commerce',
	policyTitle4: 'Disclosure to Third Parties.',
	policyTitle5: 'Protection of information.',
	policyTitle6: 'Unsubscribe.',
	policyTitle7: 'Consent.',
	policy1:
		'When you register on specialticket.net, we collect your registration information. When you: sign in to your account, make a purchase, and/or when you log out, we acquire access to this information. The information collected includes your full name, ID number, email address, telephone number, and the first six digits and last four digits of your credit/debit card.',
	policy2: 'Any information we collect about you may be used to:',
	policy21: 'Personalize your experience and respond to your individual needs.',
	policy22: 'Provide personalized advertising content.',
	policy23: 'Improve our website.',
	policy24: 'Improve customer service and support needs.',
	policy25: 'Contact you by email and/or by phone.',
	policy3:
		'We are the sole owners of the information collected on this site. Your personally identifiable information will not be sold, traded, transferred or provided to any third party company for any reason, without your consent, unless it is necessary to fulfill a request and/or transaction, for example, to fulfill an order.',
	policy4:
		'We do not sell, trade, or otherwise transfer your personally identifiable information to outside parties. This does not include trusted third parties who assist us in operating our website or conducting our business, as long as the parties agree to keep this information confidential. We believe that sharing information is necessary to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of our terms of use, or as otherwise required by the law.',
	policy5:
		'We implement various security measures to maintain the security of your personal information. We use advanced encryption to protect confidential information transmitted online. Only employees who need to perform a specific task (for example, billing or customer service) have access to personal information. The computers/servers used to store personally identifiable information are kept in a secure environment.',
	policy6:
		'We use the email address you provided to send information and updates related to your purchase, occasional company news, event information, proof of purchase, and more. If at any time you would like to unsubscribe from receiving future emails, please email us at the following email address:',
	policy7:
		'By using our site, you agree with the Privacy Policy set forth herein, you authorize us for the following use of your information: a) for the very purpose for which it has been provided; b) to consider it within our traffic statistics, thus increasing our advertising and market offer; c) to better guide the services offered here and value them at their discretion; d) to send e-mails with our newsletters, respond to concerns or comments, and e) to be used by our business partners and/or contractors for the sole purpose of sending information or promotions of interest to the user.',
	mustAcceptTerms: 'Please accept the terms and conditions.',
	labelAcept: 'Accept',
	labelPayMethod: 'Payment Method',
	labelDataphone: 'Dataphone',
	labelCash: 'Cash',
	labelAddSzInfo:
		'When adding a seat zone it will be added automatically to those event details in the future and not yet active to sell',
	labelPriceDetailsR: 'Price details',
	labelOnline: 'Online',
	labelSignUpUser: 'Sign user up',
	labelAuthCode: 'Auth Code',
	labelStatusId1: 'Available',
	labelStatusId2: 'Blocked',
	labelStatusId3: 'Reserved',
	labelBlockIdentifier: 'Identificador de bloqueo',
	labelPayType: 'Payment method',
	labelGivenBy: 'Seller',
	datafono: 'Credit Card',
	efectivo: 'Cash',
	onlineb: 'Online with Seller',
	online: 'Online',
	labelTotalServiceFee: 'Total Service fee',
	labelTotalServiceFeeIv: 'Total Service fee + tax',
	labelConsolidatedPieChart: 'Quantity and sales by pay method',
	sellPromos: 'Sell Promos',
	labelPromoSuccess: 'Promo sold successfully',
	labelPromo: 'Promotional',
	Sales: 'Sales',
	Quantity: 'Quantity',
	ServiceFee: 'ServiceFee',
	labelTotal: 'Total',
	SalesType: 'Sales total by type',
	labelPrint: 'Print tickets',
	excededQuantityCancel: "Quantity can't be higher than total",
	doTotalCancel: 'Please try with total cancellation',
	CompleteCancel: 'Complete Cancelation',
	PartialCancel: 'Partial Cancelation',
	FreeUpSeat: 'Free up',
	LockUpSeat: 'Lock up',
	CancelType: 'Cancelation Type',
	labelScansR: 'Scans Report',
	labelScansRH: 'Scans per hour',
	lblInSale: 'In Sale',
	lblSold: 'Sold',
	lblGenerated: 'Generated',
	lblScanned: 'Scanned',
	labelScans: 'Scans',
	notRegisteredAlert:
		'This user is not registered in specialticket.net, an email will be sent to accept the tickets',
	labelSource: 'Source',
	cancelTransfer: 'Cancel transfer',
	confirmTransfer: 'Confirm Transfer',
	errorModifyingStaging: 'There was an error modifying staging ticket.',
	labelAssign: 'Assign',
	labelCode: 'Code',
	labelBuyer: 'Buyer',
	labelOwnerId: 'Owner ID',
	labelOwner: 'Current owner',
	labelOwnerEmail: 'Owner email',
	labelTransactionId: 'Transaction ID',
	'payment-methods': 'Payment Methods',
	PaymentMethodsDesc: 'Review your saved payment methods.',
	legal: 'Legal',
	purchases: 'Purchases',
	seeTickets: 'See tickets',
	myTicketsDescription2: 'Download your QR codes',
	myTicketsDescription3: ' or ',
	myTicketsDescription4: 'transfer your tickets',
	myTicketsDescription5: ' to other users!',
	useSafari:
		'Please use Safari to be able to download your Apple Wallet tickets.',
	noAvailableTickets:
		'You do not own any available tickets for an event at the moment.',
	all: 'All',
	underConstruction: 'Under construction',
	from: 'From',
	sentTo: 'Sent to',
	myTransfersDescription1:
		'Review your sent or recieved tickets! To transfer tickets go to ',
	myTransfersDescription2: 'my tickets',
	recieved: 'Recieved',
	sent: 'Sent',
	staging: 'Pending',
	completed: 'Completed',
	cancel: 'Cancel',
	accept: 'Accept',
	transferTickets: 'Transfer tickets',
	transferTicketsTo: 'Choose a reciever to continue',
	transferTicketsEmail: 'Enter an email',
	RecieverDoesntExist: "Reciever doesn't exist",
	aboutToTransfer1: "You're about to transfer to ",
	aboutToTransfer2:
		'. You can cancel the transfer at anytime, while the reciever has not accepted the tickets.',
	aboutToTransfer3:
		'The transferred tickets will appear in the SpecialTicket account associated with the reciever email. ',
	aboutToTransfer4:
		'For your security, at no time will the QR codes be sent via email.',
	aboutToTransfer5:
		'Once the transfer is completed, any QR code generated before the transfer, ',
	aboutToTransfer6: 'WILL NOT BE VALID',
	aboutToTransferToUnregisteredUser:
		'The recipient must register on SpecialTicket, and then accept the transfer in My Transfers.',
	transfering: 'Transfering...',
	transferSuccess: 'Succesful transfer!',
	transferedOneTo: 'ticket transfered to ',
	transferedManyTo: 'tickets transfered to ',
	transferError: 'Failed transfer!',
	transferErrorMessage: 'The transfer has failed, please try again.',
	aboutToAcceptTransfer: 'Do you want to accept this transfer from ',
	aboutToCancelTransfer: 'Do you want to cancel this transfer to ',
	yesAccept: 'Yes, accept transfer',
	yesCancel: 'Yes, cancel transfer',
	acceptTransferSuccess: 'Transfer accepted succesfully!',
	cancelTransferSuccess: 'Transfer canceled succesfully!',
	acceptTransferError: 'Transfer could not be accepted! Try again.',
	cancelTransferError: 'Transfer could not be canceled! Try again.',
	editProfileDesc1:
		'Review and edit your profile information here. To change your ',
	editProfileDesc2: 'password ',
	editProfileDesc3: 'go to ',
	editProfileDesc4: 'change password',
	birthdate: 'Date of birth',
	name: 'Name',
	lastname: 'Surname',
	id: 'Identification',
	gender: 'Gender',
	country: 'Country',
	state: 'State',
	billing: 'Billing',
	billingDesc:
		'By default, your billing contact corresponds to your SpecialTicket.net account email. Edit your billing information here.',
	billingName: 'Billing name',
	billingVAT: 'VAT No.',
	requiredField: 'Required field',
	invalidPhone: 'Invalid phone',
	weAreSpecialTicket: '¡We are Special Ticket!',
	feature1Title: 'Secure Transfers',
	feature1Body:
		'Safely transfer tickets between users within our webpage. No more worries about losing your ticket or dealing with unreliable paper tickets.',
	feature2Title: '100% Digital Tickets',
	feature2Body:
		'All your tickets are securely stored in your account digitally. Access and manage your tickets conveniently anytime, anywhere.',
	feature3Title: 'Interactive Map',
	feature3Body:
		"Explore our interactive event maps and see exactly where you'll be seated. Get a detailed view of the venue layout, ensuring a great experience at the event.",
	unregisteredUser: 'We could not find an user registred under that email.',
	acceptTerms: 'Accept Terms & Conditions',
	available: 'Available',
	blocked: 'Blocked',
	succesfulTransaction: 'Succesful transaction!',
	declinedTransaction: 'Declined transaction!',
	canceledTransaction: 'Canceled transaction!',
	promo: 'Promotional',
	sale: 'Sale',
	gift: 'Gift',
	declined: 'Declined',
	canceled: 'Canceled',
	currentPassword: 'Current password',
	newPassword: 'New password',
	newPasswordConfirm: 'Confirm new password',
	availableDates: 'Available dates',
	noAvailableEvents:
		"We're sorry! There are no available events at the moment.",
	loadingVenue: 'Loading venue, please wait...',
	noRows: 'No rows.',
	noResults: 'No results.',
	artist: 'Artist',
	detail: 'Detail',
	category: 'Category',
	image: 'Image',
	producer: 'Producer',
	imageMustBeJpg: 'Imagen must be .jpg',
	imageTooBig: 'Image file size greater than 1MB',
	eventImageRatio: 'Image ratio must be 5/3',
	login: 'Login',
	forgotPasswordDesc: "No worries, we'll send you reset instructions.",
	forgotPasswordError: 'Unable to send reset link. Try again.',
	linkSent: 'Link sent!',
	resendLink:
		"If you haven't received the link within 2 minutes, you can request it again.",
	notFoundTitle: 'Lost in the crowd?',
	notFoundText:
		"Oops! It seems you've taken a wrong turn and got lost.. Don't worry; here you'll find various ways to get back to the center stage. Take a look at some of our upcoming events to get back in tune.",
	backHome: 'Go back home',
	errorTitle: 'Oops, Something Went Wrong!',
	errorText:
		'It seems that something unexpected has occurred on our page. Our team is already on it, working diligently to fix the issue and get things back on track. In the meantime, you can explore other parts of our website or check back later. We apologize for any inconvenience.',
	resetPassword: 'Reset your password',
	resetPasswordDesc: 'Set your new password',
	invalidLink: 'Invalid link',
	requestLinkAgain:
		"We're sorry! The link is not valid; please request it again.",
	passwordReset: 'Password reset',
	unableToResetPassword: 'Unable to reset password',
	samePassword: 'Password must be different from the current one.',
	invalidPassword: 'Invalid password',
	downloadQR: 'Download',
	ticketsDownloadSuccess: 'Tiquetes downloaded!',
	ticketsDownloadError: 'Unable to download tickets',
	forbiddenTitle: 'The event doors are closed for now!',
	forbiddenDesc:
		"The door to this event is closed for now. But, don't worry, there are many other doors open to incredible events.",
	acquireTickets1: 'Acquire your',
	acquireTickets2: 'tickets',
	understood: 'Understood',
	selectSeatsTooltip: 'Interact with the map to select your seats.',
	expiredSession: 'Session expired. Please login again.',
	closestEvents: 'Closest Events',
	securingTickets: 'Securing tickets, please wait...',
	downloadTickets: 'Download the selected tickets',
	orTransferThem: 'or transfer them to a friend.',
	productsDialogTitle: 'Maximize your experience!',
	productsDialogSubtitle:
		'Take a look into the products available for this event.',
	addToCart: 'Add to cart',
	loginToContinue: 'Please login to continue.',
	clickHereToSeeTickets: 'Click here to see your tickets',
	seeProducts: 'See Products',
	transactionNotFound: 'Could not find transaction ',
	noItemsInCart: 'Your cart is empty.',
	unableToLoadEvent: 'Unable to load event. Please try again.',
	youHavePendingTransfers1: 'You have pending transfers. Go to ',
	youHavePendingTransfers2: 'my transfers',
	youHavePendingTransfers3: ' to accept them.',
	continue: 'Continue',
	useRegularBrowser:
		'To download your tickets, please visit our website using your preferred browser.',
	noTransfersAllowed: 'Este evento no permite la transferencia de tiquetes.',
	free: 'Free',
	'Unavailable capacity': 'No dates available for this month.',
	'Capacity Overflowed': 'Some tickets are no longer available.',
	'Capacity By Quantity Overflowed': 'Some tickets are no longer available.',
	selectDate: 'Select the Date',
	selectDateDesc:
		'Search for the desired date to see the schedule availability.',
	selectTime: 'Select the Time',
	selectTimeDesc: 'Select the time you wish to attend.',
	time: 'Time',
	selectTickets: 'Choose Your Tickets',
	selectTicketsDesc:
		'Select the number of tickets you require for each option.',
	seeAvailability: 'See Availability',
	selectDateTime: 'Select Date and Time',
	step: 'Step',
	sailToAdventure: 'Sail To Adventure!',
	reserveNow: 'Reserve your ferry now',
	licensePlate: 'License Plate',
	typeLicensePlates: 'Type the licence plate number of each of the vehicles.',
	allFieldsMustBeFilled: 'All fields must be filled to continue.',
	oneDriverPerVehicle: 'One driver ticket is added per vehicle.',
	heavyVehicleRestrictions:
		'Buses and 2+ axle freight vehicles are subject to availability according to tide.',
	'Something has ocurred in the server':
		'An error has ocurred. Please try again.',
	origin: 'Origin',
	destination: 'Destination',
	changeDestination: 'Change destination',
	orderConfirmation: 'Order Confirmation',
	route: 'Route',
	instructions: 'Please take into account the following instructions:',
	instruction1: 'Arrive at the terminal 1 hour before departure time.',
	instruction2:
		'Upon arrival in the line, look for the person in charge to place you in the differentiated online ticket line.',
	instruction3:
		'Arrive early to board first to allow for smooth vehicle loading.',
	instruction4:
		'Cargo vehicles (3,4 and 5 axles) subject to tidal conditions. We recommend consulting prior to purchasing your ticket.',
	instruction5:
		'If your vehicle has a CL plate you must select LIGHT LOAD in the vehicle type.',
};

export default phrases;
