import { useTheme } from '@mui/material/styles';

export default function useStyles() {
	const theme = useTheme();

	return {
		appBar: {
			boxShadow: 'none',
			transition: 'all ease-in-out 300ms',
			li: {
				color: theme.palette.secondary.contrastText,
			},
			backgroundColor: { xs: '#020626', md: 'transparent' },
		},
		appBarScrolled: {
			backgroundColor: `${theme.palette.secondary.main} !important`,
		},
		container: {
			paddingInline: 2,
			paddingBlock: 1.5,
		},
		containerSlim: {
			height: '6vh',
			maxHeight: '6vh',
			paddingBlock: 0,
		},
		drawer: {
			display: { xs: 'block', md: 'none' },
			'& .MuiDrawer-paper': {
				backgroundColor: theme.palette.secondary.dark,
				boxSizing: 'border-box',
				width: '100%',
				maxWidth: 450,
			},
		},
		drawerSlim: {
			display: 'block',
		},
		burger: {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
			boxShadow: `2px 2px 8px 0px ${theme.palette.primary.light}`,
		},
		burgerBox: {
			flexGrow: 1,
			display: { xs: 'flex', md: 'none' },
		},
		signInBox: {
			flexGrow: 1,
			display: { xs: 'flex', md: 'none' },
			justifyContent: 'flex-end',
		},
		signInIcon: {
			color: theme.palette.secondary.contrastText,
		},
		leftBox: {
			marginLeft: theme.spacing(4),
			flexGrow: 1,
			display: { xs: 'none', md: 'flex' },
			alignItems: 'center',
			gap: theme.spacing(1),
		},
		rightBox: {
			flexGrow: 0,
			display: { xs: 'none', md: 'flex' },
			alignItems: 'center',
		},
		myTickets: {
			[theme.breakpoints.down(1205)]: {
				display: 'none',
			},
		},
		langBox: {
			[theme.breakpoints.down(1030)]: {
				display: 'none',
			},
		},

		/* Drawer styles */
		drawerBox: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			paddingBlock: theme.spacing(2),
		},
		drawerHeader: {
			display: 'flex',
			width: '100%',
			alignItems: 'center',
			paddingInline: theme.spacing(3),
			gap: theme.spacing(2),
			zIndex: 10,
		},
		profileBox: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(1),
			paddingInline: theme.spacing(3),
			marginTop: theme.spacing(3),
			color: theme.palette.secondary.contrastText,
		},
		profileList: {
			'*': { color: theme.palette.secondary.contrastText },
		},
		logoBox: {
			flexGrow: 1,
		},
		closeDrawer: {
			color: theme.palette.secondary.contrastText,
		},
		list: {
			pt: 6,
			width: '100%',
			height: '100%',
			div: {
				color: theme.palette.secondary.contrastText,
			},
		},
		divider: {
			height: 3,
			width: '100%',
			borderRadius: '0.3rem',
			background: theme.palette.primary.main,
		},
	};
}
