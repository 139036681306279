import { keyframes, useTheme } from '@mui/material';

export default function useStyles() {
	const theme = useTheme();

	// Define the pulsate animation using MUI's keyframes utility
	const pulsate = keyframes({
		'0%': {
			boxShadow: `0 0 0 0px ${theme.palette.primary.main}`,
		},
		'30%': {
			boxShadow: `0 0 0 10px rgba(0, 0, 0, 0)`,
		},
		'100%': {
			boxShadow: `0 0 0 0px rgba(0, 0, 0, 0)`,
		},
	});

	return {
		root: {
			width: { xs: 140, md: 150 },
			minHeight: { xs: 140, md: 150 },
			border: `3px solid ${theme.palette.glass.main}`,
			borderRadius: '1.3rem',
			color: theme.palette.secondary.contrastText,
		},
		active: {
			borderColor: theme.palette.primary.main,
		},
		content: {
			p: theme.spacing(2),
			height: '100%',
			paddingBlock: theme.spacing(2),
			paddingInline: theme.spacing(1.5),
		},
		name: {
			fontSize: 14,
			textAlign: 'center',
		},
		price: {
			textAlign: 'center',
			color: theme.palette.primary.main,
		},
		quantitySelector: {
			mt: 'auto',
			width: '100%',
			color: theme.palette.secondary.contrastText,
			background: theme.palette.glass.main,
			paddingInline: theme.spacing(0.5),
			borderRadius: '0.3rem',
		},
		icon: {
			color: theme.palette.secondary.contrastText,
			border: `2px solid transparent`,
		},
		quantity: {
			flex: 1,
			textAlign: 'center',
			borderRadius: '0.3rem',
			height: 40,
			lineHeight: 2.5,
		},
		addActive: {
			border: `2px solid ${theme.palette.primary.main}`,
			animation: `${pulsate} 1.5s infinite ease-out`, // Use the pulsate animation
		},
	};
}
