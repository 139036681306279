import { theme } from '@/theme/theme';

export default function useStyles() {
	return {
		card: {
			padding: { xs: theme.spacing(3), md: theme.spacing(12) },
			color: theme.palette.secondary.contrastText,
			p: {
				fontWeight: 100,
				lineHeight: 2,
				textAlign: 'justify',
			},
			strong: {
				color: theme.palette.secondary.contrastText,
			},
		},
		content: {
			color: theme.palette.secondary.contrastText,
			'> strong': {
				fontSize: theme.typography.h5.fontSize,
			},
			p: {
				fontWeight: 100,
				lineHeight: 2,
				marginBottom: 2,
			},
			strong: {
				color: theme.palette.secondary.contrastText,
			},
		},
		icon: {
			width: { xs: 64, md: 124 },
			height: { xs: 64, md: 124 },
			color: theme.palette.secondary.contrastText,
		},
		title: {
			textTransform: 'uppercase',
			color: theme.palette.secondary.contrastText,
			fontWeight: 'bold',
			fontSize: { xs: '1rem', sm: '1.3rem' },
		},
		divider: {
			'::before, ::after': {
				borderTop: '3px solid rgb(255 255 255 / 37%)',
			},
		},
		intro: {
			textAlign: 'center',
			lineHeight: '1.6rem',
			color: theme.palette.secondary.contrastText,
			fontStyle: 'italic',
			'> p': {
				color: theme.palette.secondary.contrastText,
			},
			marginBottom: 96,
		},
	};
}
