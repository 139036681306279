/* eslint-disable no-dupe-keys */
import { circularProgressClasses } from '@mui/material';
import { theme } from '@/theme/theme';

export default function useStyles() {
	return {
		logoContainer: {
			position: 'relative',
			display: 'flex',
			flexWrap: 'nowrap',
			justifyContent: 'center',
			alignItems: 'center',
			gap: 4,
		},
		logoTypography: {
			overflow: 'hidden',
		},
		logoDot: {
			position: 'relative',
			width: 28,
			height: 28,
			zIndex: 2,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		progressContainer: {
			position: 'absolute',
			zIndex: -1,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		progress: {
			color: theme.palette.primary.main,
			animationDuration: '700ms',
			[`& .${circularProgressClasses.circle}`]: {
				strokeLinecap: 'round',
			},
		},
	};
}
