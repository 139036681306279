import { axiosInstance } from '..';

export const refreshSession = async (uId, token) => {
	const url = `/user/RefreshSession`;
	const res = await axiosInstance.post(
		url,
		{ UserId: uId },
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		},
	);
	return res.data;
};
