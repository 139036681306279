import { create } from 'zustand';
import { LANGS } from '@utils/enums';

const useStore = create(set => ({
	// user
	user: {},
	setUser: user => set(() => ({ user })),
	removeUser: () => set({ user: {} }),

	// lang
	lang: LANGS.ES,
	setLang: lang => set(() => ({ lang })),

	// loading
	isLoading: false,
	setLoading: isLoading => set(() => ({ isLoading })),

	// redirect to after login
	redirectTo: null,
	setRedirect: redirectTo => set(() => ({ redirectTo })),

	// categories
	categories: null,
	setCategories: categories => set(() => ({ categories })),

	// influencer
	influencer: null,
	setInfluencer: influencer => set(() => ({ influencer })),
}));

export default useStore;
