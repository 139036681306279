import axios from 'axios';
import { config } from '@/config';

const axiosInstance = axios.create({
	baseURL: config.api,
});

// Create a request interceptor
axiosInstance.interceptors.request.use(config => {
	// Create a new CancelToken source for each request
	const cancelToken = axios.CancelToken;
	const cancelTokenSource = cancelToken.source();

	if (config.data && typeof config.data === 'object') {
		// Iterate through the payload and sanitize string values
		for (const key in config.data) {
			if (typeof config.data[key] === 'string') {
				config.data[key] = config.data[key].trim();
			}
		}
	}

	// Include the cancelToken in the request configuration
	config.cancelToken = cancelTokenSource.token;

	return config;
});

axiosInstance.interceptors.response.use(
	response => response,
	error => {
		if (isUnauthorized(error)) {
			redirectToSignIn();
		}
		return Promise.reject(error);
	},
);

const isUnauthorized = error => {
	return (
		error.response &&
		error.response.status === 401 &&
		!error.response.config.url.includes('RefreshSession')
	);
};

const redirectToSignIn = () => {
	window.location.href = '/auth/signin?error=unauth';
};

export { axiosInstance }; // Export the axios instance only
