import { useTheme } from '@mui/material';

export default function useStyles() {
	const theme = useTheme();
	return {
		stepNumber: {
			fontWeight: 500,
			fontSize: 12,
			color: theme.palette.primary.main,
			textTransform: 'uppercase',
		},
		stepTitle: {
			fontWeight: 500,
			fontSize: props => (props.fontSize === 'small' ? 14 : 18),
		},
		description: {
			marginTop: theme.spacing(1),
			fontWeight: 300,
			color: theme.palette.grey[300],
			fontSize: props => (props.fontSize === 'small' ? 12 : 14),
		},
		boxContainer: {
			display: 'flex',
			flexDirection: 'column',
		},
	};
}
