import { useTheme } from '@mui/material';

export default function useStyles() {
	const theme = useTheme();
	return {
		container: {},
		content: {
			marginTop: { xs: theme.spacing(6), sm: theme.spacing(15) },
		},
		header: {
			textTransform: 'uppercase',
			color: theme.palette.secondary.contrastText,
			fontWeight: 'bold',
			fontSize: { xs: 32, md: 48 },
			textAlign: 'center',
		},
		subheader: {
			textTransform: 'uppercase',
			color: theme.palette.secondary.contrastText,
			fontSize: 16,
			textAlign: 'center',
		},
		optionBox: {
			display: 'flex',
			flexDirection: 'column',
			width: { xs: '90vw', sm: 550 },
			borderRadius: '1.3rem',
			boxShadow: theme.shadows[12],
			aspectRatio: { xs: 'none', sm: '1792 / 1024' },
			overflow: 'hidden',
			borderTop: `4px solid ${theme.palette.primary.main}`,
		},
		optionContent: {
			flex: 1,
			p: theme.spacing(3),
			backdropFilter: 'blur(3px)',
			background: 'rgba(7, 7, 104, 0.3)',
			height: '100%',
		},
		optionHeader: {
			borderRadius: '1.3rem',
			width: 'fit-content',
		},
		label: {
			fontSize: { xs: 12, sm: 18 },
		},
		destination: {
			fontSize: { xs: 20, sm: 36 },
			textTransform: 'uppercase',
			fontWeight: 900,
		},
		route: {
			fontSize: { xs: 14, sm: 16 },
		},
	};
}
