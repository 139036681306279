import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import HelperText from '../components/helperText/helperText';

const base = createTheme({});

const palette = createTheme({
	palette: {
		primary: {
			main: '#fcee21', // Sunshine Yellow
			contrastText: '#0a5b81', // Sapphire Blue
		},
		secondary: {
			main: '#15113a', // Midnight Blue
			light: '#0a5b81', // Sapphire Blue
			contrastText: '#f6f6f6', // Snow White
		},
		glass: {
			light: '#ffffff11',
			main: '#ffffff33', // Transparent White
			dark: '#434060', // No Transparency
		},
		error: {
			main: '#ed6c02',
			light: '#4A2247',
			contrastText: '#91798F',
		},
		success: {
			main: '#17B779',
			light: '#193F4C',
			contrastText: '#91798F',
		},
		warning: {
			main: '#ed6c02',
			light: '#4A2247',
			contrastText: '#91798F',
		},
		info: {
			main: '#1783b7',
			light: '#19274c',
			contrastText: '#91798F',
		},
		seats: {
			available: '#e5e5e5',
			reserved: '#707070',
			selected: 'rgb(65, 173, 249)',
			owned: '#30ff15',
		},
		gradient: {
			primary: 'rgba(34,207,253,1)',
			secondary: 'rgba(179,35,254,0.4)',
		},
	},
});

const components = createTheme({
	components: {
		MuiTypography: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					color: theme.palette.secondary.contrastText,
				}),
			},
		},
		MuiSvgIcon: {
			styleOverrides: {
				root: {
					transition: 'inherit',
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					fontSize: 18,
					fontWeight: 900,
					minWidth: 'fit-content',
					'&.Mui-disabled': {
						'*': {
							color: 'rgba(0, 0, 0, 0.26)',
						},
					},
				},
				contained: {
					borderRadius: '1.3rem',
					border: 'none',
					textTransform: 'uppercase',
					backgroundColor: palette.palette.primary.main,
					color: palette.palette.primary.contrastText,
					boxShadow: `4px 4px 12px 0px ${palette.palette.primary.light}`,
					a: {
						color: palette.palette.primary.contrastText,
					},
					'*': {
						color: palette.palette.primary.contrastText,
					},
					':hover': {
						color: palette.palette.secondary.contrastText,
						background: palette.palette.secondary.light,
						boxShadow: `4px 4px 12px 0px ${palette.palette.secondary.light}`,
						transform: 'scale(1.04)',
						'*': {
							color: palette.palette.secondary.contrastText,
						},
					},
				},
				text: {
					color: palette.palette.secondary.contrastText,
					textTransform: 'capitalize',
					':hover': {
						color: palette.palette.primary.main,
						backgroundColor: 'transparent',
						transform: 'scale(1.04)',
						'*': {
							color: palette.palette.primary.main,
						},
					},
					'*': {
						color: palette.palette.secondary.contrastText,
					},
				},
			},
			defaultProps: {
				disableRipple: true,
				disableFocusRipple: true,
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					a: {
						color: palette.palette.secondary.contrastText,
					},
					':hover': {
						'*': {
							color: palette.palette.primary.main,
						},
					},
				},
			},
		},
		MuiListItem: {
			styleOverrides: {
				root: {
					a: {
						color: palette.palette.secondary.contrastText,
					},
					':hover': {
						'*': {
							color: palette.palette.secondary.contrastText,
						},
					},
				},
			},
		},
		MuiListItemButton: {
			styleOverrides: {
				root: {
					a: {
						color: palette.palette.secondary.contrastText,
					},
				},
			},
		},
		MuiCard: {
			styleOverrides: {
				root: {
					backgroundColor: palette.palette.glass.main,
					WebkitBackdropFilter: 'blur(10px)',
					backdropFilter: 'blur(10px)',
					borderRadius: base.shape.borderRadius,
					boxShadow: 'none',
				},
			},
		},
		MuiAccordion: {
			styleOverrides: {
				root: {
					backgroundColor: palette.palette.glass.main,
					WebkitBackdropFilter: 'blur(5px)',
					backdropFilter: 'blur(5px)',
					borderRadius: base.shape.borderRadius,
					boxShadow: 'none',
				},
			},
		},
		MuiFilledInput: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					background: palette.palette.glass.main,
					color: palette.palette.secondary.contrastText,
					borderRadius: '0.3rem',
					border: ownerState.error
						? `2px solid ${theme.palette.error.main}`
						: 'none',
				}),
			},
			defaultProps: ({ ownerState, theme }) => ({
				disableUnderline: true,
				InputLabelProps: {
					color: 'primary',
				},
			}),
		},
		MuiTextField: {
			defaultProps: {
				InputLabelProps: {
					sx: { color: palette.palette.secondary.contrastText },
				},
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					'&.Mui-error': {
						color: `${theme.palette.secondary.contrastText} !important`,
					},
				}),
			},
		},
		MuiFormHelperText: {
			defaultProps: {
				component: HelperText,
			},
		},
		MuiStepLabel: {
			styleOverrides: {
				label: {
					color: palette.palette.secondary.contrastText,
					'&.Mui-completed': {
						color: palette.palette.secondary.contrastText,
					},
					'&.Mui-active': {
						color: palette.palette.secondary.contrastText,
					},
				},
			},
		},
		MuiDateCalendar: {
			styleOverrides: {
				root: {
					color: palette.palette.secondary.contrastText,
					'*': {
						color: palette.palette.secondary.contrastText,
					},
				},
			},
		},
		MuiDayCalendar: {
			styleOverrides: {
				weekDayLabel: {
					color: palette.palette.secondary.contrastText,
				},
			},
		},
		MuiPickersDay: {
			styleOverrides: {
				root: {
					color: palette.palette.secondary.contrastText,
					'&.Mui-disabled': {
						color: `${palette.palette.glass.main} !important`,
					},
				},
			},
		},
		MuiPickersYear: {
			styleOverrides: {
				yearButton: {
					color: palette.palette.secondary.contrastText,
					'&.Mui-disabled': {
						color: `${palette.palette.glass.main} !important`,
					},
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					zIndex: 'revert',
				},
			},
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					'*': {
						color: palette.palette.primary.main,
					},
				},
			},
		},
		MuiPopover: {
			styleOverrides: {
				paper: ({ ownerState, theme }) => ({
					backgroundColor: theme.palette.secondary.main,
					color: theme.palette.secondary.contrastText,
					borderRadius: '1.3rem',
					boxShadow: theme.shadows[4],
				}),
			},
		},
		MuiTableSortLabel: {
			styleOverrides: {
				icon: ({ ownerState, theme }) => ({
					color: theme.palette.primary.main + ' !important',
				}),
			},
		},
		MUIDataTable: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					backgroundColor: theme.palette.glass.main,
					boxShadow: theme.shadows[4],
					borderRadius: '1.3rem',
					padding: '0.3rem',
				}),
				responsiveBase: ({ ownerState, theme }) => ({
					'::-webkit-scrollbar-track': {
						backgroundColor: 'transparent',
					},
					'::-webkit-scrollbar-thumb': {
						backgroundColor: theme.palette.glass.main,
						border: 'none',
					},
				}),
			},
		},
		MUIDataTableBodyCell: {
			styleOverrides: {
				root: {
					borderBottom: 'none',
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					borderBottom: 'none',
					color: theme.palette.secondary.contrastText + ' !important',
				}),
			},
		},
		MUIDataTableHeadCell: {
			styleOverrides: {
				fixedHeader: ({ ownerState, theme }) => ({
					backgroundColor: 'transparent',
					borderBottom: `3px solid ${theme.palette.primary.main}`,
				}),
				data: ({ ownerState, theme }) => ({
					textTransform: 'uppercase',
				}),
				sortActive: ({ ownerState, theme }) => ({
					color: theme.palette.primary.main,
				}),
			},
		},
		MUIDataTableSelectCell: {
			styleOverrides: {
				headerCell: ({ ownerState, theme }) => ({
					backgroundColor: 'transparent',
				}),
			},
		},
		MUIDataTableToolbar: {
			styleOverrides: {
				icon: ({ ownerState, theme }) => ({
					color: theme.palette.glass.main,
				}),
			},
		},
		MUIDataTableFilter: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					backgroundColor: theme.palette.glass.main,
					borderRadius: '1.3rem',
					boxShadow: theme.shadows[4],
				}),
				title: ({ ownerState, theme }) => ({
					color: theme.palette.secondary.contrastText,
				}),
				checkboxListTitle: ({ ownerState, theme }) => ({
					color: theme.palette.secondary.contrastText,
				}),
				checkboxFormControlLabel: ({ ownerState, theme }) => ({
					color: theme.palette.secondary.contrastText,
					fontWeight: 100,
				}),
			},
		},
		MUIDataTableSearch: {
			styleOverrides: {
				main: ({ ownerState, theme }) => ({
					backgroundColor: theme.palette.glass.main,
					borderRadius: '1.3rem',
					paddingInline: '1rem',
				}),
				searchIcon: ({ ownerState, theme }) => ({
					color: theme.palette.primary.main,
				}),
				clearIcon: ({ ownerState, theme }) => ({
					color: theme.palette.glass.main,
				}),
			},
		},
		MUIDataTableViewCol: {
			styleOverrides: {
				title: ({ ownerState, theme }) => ({
					color: theme.palette.secondary.contrastText,
				}),
				label: ({ ownerState, theme }) => ({
					color: theme.palette.secondary.contrastText,
				}),
			},
		},
		MUIDataTablePagination: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					color: theme.palette.secondary.contrastText,
				}),
			},
		},
		MUIDataTableToolbarSelect: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					backgroundColor: 'transparent',
					boxShadow: 'none',
					padding: 0,
				}),
				iconButton: ({ ownerState, theme }) => ({
					color: theme.palette.glass.main,
				}),
			},
		},
		MUIDataTableFilterList: {
			styleOverrides: {
				chip: ({ ownerState, theme }) => ({
					backgroundColor: theme.palette.glass.main,
					color: theme.palette.secondary.contrastText,
				}),
			},
		},
	},
});

export const theme = responsiveFontSizes(createTheme(components, palette));
