const phrases = {
	labelHome: 'Inicio',
	labelAbout: 'Acerca de',
	labelContact: 'Contacto',
	labelCategories: 'Categorías',
	labelSignup: 'Registrarse',
	labelLogin: 'Ingresar',
	'labelBanner Text': 'Lorem Ipsum is simply dummy text of the printing..',
	labelReadMore: 'Ver eventos',
	'labelSelect Category': 'Seleccionar Categoría',
	'labelSelect Venue': 'Seleccionar Lugar del Evento',
	'labelSearch Here': 'Buscar aquí',
	labelSearch: 'Buscar',
	upcomingEvents: 'Próximos Eventos',
	labelViewMore: 'Ver Más',
	labelEventCatgeory: 'Categoría de eventos',
	labelOtherEvents: 'Eventos',
	labelLatestPosts: 'Ultimas Publicaciones',
	labelNewsLetter: 'Boletín',
	'labelFollow Us': 'Síganos',
	labelCopyRight: 'DERECHOS RESERVADOS',
	labelSeatAvailable: 'Ver fechas disponibles',
	labelTags: 'Etiquetas',
	labelEvent1: '????',
	labelEventDetail: 'Detalle de evento',
	labelStartDate: 'Fecha de Inicio',
	labelEndDate: 'Fecha Final',
	labelVenue: 'Lugar',
	labelAddress: 'Dirección',
	labelGetDirection: 'Obtener Dirección',
	labelOrganizers: 'Organizadores',
	labelSponsors: 'Patrocinadores',
	labelShare: 'Compartir',
	labelEventDetails: 'Entérese de todos los eventos que tenemos para usted',
	labelAllConcerts: 'Todos los Eventos',
	labelSeeTickets: 'Comprar Tiquetes',
	labelSeeTicketsShort: 'Comprar',
	labelLineUp: 'Line-Up',
	labelAddOns: 'Complementos',
	labelLoadMore: 'Cargar Más',
	labelSubmit: 'Enviar',
	labelNoOfTickets: 'No. de Tiquetes',
	labelLowestPrice: 'Precio Menor',
	labelBestSeats: 'Mejores Asientos',
	"labelDon'tMissEvents": 'No se pierda nuestros eventos...',
	labelEnterYourEmail: 'Digite su Email',
	labelSubscribe: 'Subscribirse',
	labelFirstName: 'Nombre',
	firstNameRequired: 'Por favor indíquenos su nombre',
	labelLastName: 'Apellido',
	lastNameRequired: 'Por favor indíquenos su apellido',
	labelEmailAddress: 'Correo electrónico',
	labelEmailAddressC: 'Confirmar correo electrónico',
	emailRequired: 'Por favor indíquenos su correo electrónico',
	emailReceiptRequired:
		'Por favor indíquenos el correo electrónico del receptor',
	phoneNumber: 'Teléfono',
	phoneRequired: 'Por favor complete su número telefónico',
	labelPassword: 'Contraseña',
	passwordRequired: 'Debes indicar su contraseña',
	passwordLen: 'Mínimo 5 caracteres.',
	passwordReg:
		'La contraseña debe contener mínimo 1 letra mayúscula, una letra minúscula y 1 carácter especial',
	passwordMatch: 'Las contraseñas no coinciden',
	'labelConfirm Password': 'Confirmar Contraseña',
	labelNotifications: 'Notificaciones',
	labelSelectOption: 'Seleccione su opción',
	labelYes: 'Sí',
	labelNo: 'No',
	labelComments: 'Comentarios',
	labelTypeComments: 'Escriba sus comentarios aquí..',
	labelAgree: 'Acepto los',
	labelTerms: 'términos',
	labelAnd: 'y',
	labelPrivacy: 'privacidad',
	labelMarkField: 'Por favor seleccione esta opción',
	labelAlreadyHaveAccount: '¿Ya tiene su cuenta?',
	labelHere: 'aquí',
	labelBackTo: 'Regresar a la página de',
	labelBackPage: '',
	labelRememberMe: 'Recuérdeme',
	'labelForgotPassword ?': '¿Olvidó su contraseña?',
	labelForgotPassword: '¿Olvidó su contraseña?',
	labelOrLoginwith: 'O ingrese con',
	"labelDon'tHaveAccount": '¿No tiene una cuenta?',
	labelDontThenSign: 'Regístrese',
	labelForFree: 'gratis',
	labelBacktoLogin: 'Regrese al ingreso',
	labelMyprofile: 'Mi Perfil',
	editProfile: 'Editar perfil',
	userUpdated: '¡Datos actualizados existosamente!',
	userNotUpdated: 'No fue posible actualizar los datos.',
	passwordUpdated: 'Contraseña actualizada existosamente!',
	passwordNotUpdated: 'No fue posible actualizar la contraseña.',
	notifications: 'Notificaciones',
	notificationsDetail:
		'Deseo recibir información de futuros eventos y promociones por medio de correo y SMS.',
	tickets: 'Mis Tiquetes',
	TicketsDesc: 'Encuentre sus tiquetes disponibles.',
	transactions: 'Mis Transacciones',
	TransactionsDesc: 'Información de sus transacciones realizadas.',
	transfers: 'Mis Transferencias',
	TransfersDesc: 'Información de todas sus transferencias.',
	products: 'Mis Productos',
	ProductsDesc: 'Encuentre aquí sus productos adquiridos.',
	labelTicketNav: 'Mis Tiquetes',
	labelTicketResult: 'Descargar Tiquetes',
	labelOnGoingEvents: 'Eventos en Curso',
	labelLogout: 'Cerrar Sesión',
	'labelD.0.B': 'Fecha de Nacimiento',
	labelGender: 'Género',
	labelCurrentCity: 'Región',
	labelCountry: 'País',
	labelZipCode: 'Código Postal',
	labelIdProof: 'Cédula',
	'personal-information': 'Información Personal',
	PersonalInformationDesc: 'Mantenimiento de información sobre su cuenta.',
	labelContactInformation: 'Información de Contacto',
	labelUploadIdProof: 'Indique su ID',
	labelEventCategories: 'Categorías de eventos',
	labelLocation: 'Ubicación',
	labelOrganizerName: 'Nombre del organizador',
	labelPerformerName: 'Nombre del intérprete',
	labelOrganizerPhoneNo: 'Número de teléfono del organizador',
	labelDay: 'Día',
	labelEventName: 'Nombre del evento',
	labelSelectRange: 'Seleccionar rango',
	labelSeatNumber: 'Número de asiento',
	labelSeatPrice: 'Precio del asiento',
	labelBuyTicket: 'Comprar tiquetes',
	labelGetTicket: 'Obtener tiquetes',
	NoEmailFound: 'Correo electrónico no encontrado',
	EmailResetSuccess:
		'Hemos enviado un enlace de recuperación a su correo electrónico',
	ForgotEmailRequired: 'Debe indicar su correo electrónico',
	SignAlreadyRegEmail: 'Correo electrónico ya registrado.',
	SignAlreadyRegUserIdProof: 'Cédula ya registrada.',
	SignSuccess: '¡Se ha registrado exitosamente!',
	InternalError: 'Ocurrió un error, intente de nuevo más tarde',
	forgotLabelReset: 'Reiniciar',
	forgotLabelForgot: '¿Olvidó su',
	forgotLabelPassword: 'contraseña?',
	forgotLabelDesc: 'Al parecer no recuerda su contraseña',
	forgotLabelExpiredInstruction:
		'El vínculo de recuperación expiró, por favor solicítelo de nuevo en',
	forgotLabel1stDesc:
		'Ingrese el correo electrónico de su registro y le enviaremos las instrucciones',
	labelNewPassword: 'Nueva contraseña',
	labelPwdRequired: 'Se requiere nueva contraseña',
	userNoExist: 'Usuario no existe',
	usesOldPassword: 'No puede usar la contraseña anterior',
	PasswordResetSuccess: 'Contraseña reiniciada exitosamente',
	labelProvideValidEmail: 'Ingrese un correo electrónico válido',
	labelSignIn: 'Iniciar sesión',
	userNotFound: 'Usuario no registrado.',
	wrongPassword: 'Contraseña incorrecta.',
	labelDob: 'Fecha de nacimiento',
	Male: 'Masculino',
	Female: 'Femenino',
	RatherNotSay: 'Prefiero no decirlo',
	labelHi: 'Hola',
	labelInputToSearch: 'Debes indicar los valores para realizar la búsqueda',
	labelAddTicket: 'Agregar',
	labelSec: 'Zona',
	labelRow: 'Fila',
	labelTable: 'Mesa',
	labelSeat: 'Asiento',
	labelQuantity: 'Cantidad',
	countryRequired: 'Por favor indicar el país',
	regionRequired: 'Por favor indicar la región',
	genderRequired: 'Por favor indicar tu género',
	dobRequired: 'Por favor indicar su fecha de nacimiento',
	userIdProofRequired: 'Por favor indicar su cédula',
	zipCodeRequired: 'Por favor indicar su código postal',
	invalidDob: 'Por favor indicar una fecha válida',
	invalidFirstName: 'Por favor indicar un nombre válido',
	invalidLastName: 'Por favor indicar un apellido válido',
	invalidGender: 'Por favor indicar un género válido',
	invalidPhoneNo: 'Por favor indicar un número telefónico válido',
	invalidZipCode: 'Por favor indicar un código postal válido',
	invalidUserIdProof:
		'Por favor indicar solo los valores alfabéticos y númericos de su identificación',
	labelSave: 'Guardar',
	editSucess: 'Su perfil se ha editado exitosamente',
	editFail: 'Hubo un error editando su perfil',
	labelSeatZone: 'Zona',
	labelTicketDetails: 'Administrar Tiquetes',
	labelTransactionDetails: 'Detalles de la Transacción',
	transactionDetails: 'Detalles de la transacción',
	labelClose: 'Cerrar',
	labelTickets: 'Tiquetes',
	labelTransferTicket: 'Transferir',
	labelTransferTicketShort: 'Transferir tiquetes',
	transferTicketInfo:
		'Use esta opción para transferir los tiquetes seleccionados a otra persona registrada en specialticket.net',
	generateTicketInfo:
		'Use esta opción para generar los tiquetes seleccionados para el ingreso al evento.',
	labelReceiptEmailAddress: 'Correo electrónico de quien recibe',
	labelTicketTransferSuccess: 'Los tiquetes fueron transferidos exitosamente',
	ReceiverDoesntExist:
		'El usuario receptor debe estar registrado en la página web.',
	UnableToTransferTickets: 'No puede transferir este tiquete',
	labelActions: 'Acciones',
	labelGenerateQR: 'Generar QR',
	labelBack: 'Volver',
	transferEmailRegistered:
		'El correo electrónico debe estar registrado en specialticket.net.',
	labelSelect: 'Seleccionar todos',

	labelTicket: 'Tiquete',
	labelMustCompleteData: 'Debe completar la información de su pérfil',
	labelForbidden: 'No tiene acceso para acceder al recurso solicitado',
	labelForbiddenMessage: 'Por favor inicie sesión para ingresar.',
	labelNotFound: 'No encontramos lo que buscaba',
	labelNotFoundMessage: 'El recurso que busca no existe en nuestro sitio web',
	userInactive: 'El usuario se encuentra inactivo.',
	NewOwnerCompleteProfile: 'El usuario receptor debe completar su pérfil',
	labelVerify: 'Verificar',
	labelAboutToTransfer: 'Está por transferir estos tiquetes a:',
	labelSameEmail: 'No puede transferir tiquetes a su email.',
	NewOwnerInactive: 'Este usuario no está disponible para recibir tiquetes.',
	userIdProofReg: 'La cédula se encuentra en uso en otra cuenta.',
	labelNoGender: 'Prefiero no decirlo',
	labelSearchResult: 'Resultados de la búsqueda',
	labelSearchNotFound: 'No se encontraron resultados',
	MacOsError: 'Su dispositivo parece no soportar esta acción',
	labelDownloadTicket: 'Descargar',
	labelTotalPrice: 'Precio Total: ',
	labelMyTickets: 'Mis Tiquetes',
	labelRecommendedSeats: 'Asientos Recomendados',
	labelLegend: 'Leyenda',
	labelAvailable: 'Disponible',
	labelReserved: 'No disponible',
	labelSelected: 'Seleccionado',
	labelMySeats: 'Mis asientos',
	TransactionDetailForbidden: 'Hubo un error al consultar esta transacción',
	inProgress: 'En proceso',
	labelConfirmed: 'Confirmada',
	labelDeclined: 'Declinada',
	labelTransStatus: 'Estado de la transacción',
	labelLastCardBrandUsed: 'Método de pago',
	labelProcessedTime: 'Fecha de pago',
	labelTransactionSummary: 'Resumen de la Transacción',
	labelTotalAmount: 'Precio total',
	labelNotNumbered: 'No numerado',
	labelAvailability: 'tiquetes',
	labelThanksTransaction:
		'Gracias por su compra... ¡Disfrute al máximo su experiencia!',
	labelVenueName: 'Nombre del Lugar del evento',
	labelContactNumber: 'Número de contacto',
	labelState: 'Estado',
	labelCity: 'Ciudad',
	labelUploadImage: 'Subir imagen',
	venueNameRequired: 'Nombre del lugar del evento requerido',
	contactNumberRequired: 'Número de contacto requerido',
	stateRequired: 'Estado requerido',
	cityRequired: 'Ciudad requerida',
	addressRequired: 'Dirección requerida',
	venueImageRequired: 'Imagen del lugar del evento requerida',
	invalidEmail: 'Email inválido',
	imageShouldSvg: 'La imagen debe estar en formato SVG',
	addVenue: 'Añadir lugar',
	save: 'Guardar',
	success: 'Éxito',
	labelZone: 'Zona',
	labelSeatZoneName: 'Zona',
	labelSeatZoneAvailability: 'Disponibilidad',
	labelSeatZoneType: 'Tipo de la zona de asientos',
	labelCapacity: 'Capacidad',
	seatZoneNameRequired: 'Nombre de la zona de asientos requerido',
	seatZoneavailabilityRequired:
		'Disponibilidad de la zona de asientos requerido',
	colorRequired: 'Color requerido',
	venueRequired: 'Lugar requerido',
	seatZoneTypeRequired: 'Tipo de la zona de asientos requerido',
	capacityRequired: 'Capacidad requerida',
	numerated: 'Numerado',
	noNumerated: 'No Numerado',
	active: 'Activo',
	labelEventCategoryName: 'Nombre de la categoría del evento',
	labelEventCategoryDetail: 'Detalles de la categoría del evento',
	eventCategoryNameRequired: 'Nombre de la categoría del evento requerido',
	eventCategoryDetailRequired: 'Detalles de la categoría del evento requerido',
	notAnImage: 'El archivo no es una imagen',
	imageRequired: 'Imagen requerida',
	labelAddSponsors: 'Añadir patrocinadores',
	labelSponsorName: 'Nombre del patrocinador',
	labelSponsorEmail: 'Correo electrónico del patrocinador',
	labelSponsorWebsite: 'Página web del patrocinador',
	labelSponsorPhoneNumber: 'Número telefónico del patrocinador',
	labelSponsorLogo: 'Logo del patrocinador',
	sponsorNameRequired: 'Nombre del patrocinador Requerido',
	sponsorEmailRequired: 'Correo electrónico del patrocinador Requerido',
	sponsorWebsiteRequired: 'Página web del patrocinador Requerida',
	sponsorPhoneNumberRequired: 'Número telefónico del patrocinador Requerido',
	sponsorLogoRequired: 'Logo del patrocinador Requerido',
	invalidUrl: 'Sitio web es inválido',
	seatNumberRequired: 'Número de asiento Requerido',
	seatZoneRequired: 'Zona de asiento Requerida',
	rowRequired: 'Fila Requerida',
	addSeat: 'Añadir asientos',
	performerNameRequired: 'Nombre del artista Requerido',
	eventNameRequired: 'Nombre del evento Requerido',
	eventDetailsRequired: 'Detalles del evento Requerido',
	eventCategoryRequired: 'Categoría del evento Requerido',
	eventImageRequired: 'Imagen del evento Requerida',
	labelAddEvent: 'Añadir evento',
	labelEventCategory: 'Categoría del evento',
	labelEventImage: 'Imagen del evento',
	labelEventCategoryImage: 'Imagen de la categoría del evento',
	event: 'Evento',
	eventList: 'Lista de eventos',
	venueList: 'Lista de lugares',
	zone: 'Zona',
	organizers: 'Organizadores',
	sponsors: 'Patrocinadores',
	users: 'Usuarios',
	seats: 'Asientos',
	labelAddEventCategory: 'Añadir categoría del evento',
	labelEditEventCategory: 'Editar categoría del evento',
	labelAddEventDetails: 'Añadir fechas del evento',
	labelEditEventDetails: 'Editar fechas del evento',
	labelEventStartDate: 'Fecha inicio de evento',
	labelEventEndDate: 'Fecha final del evento',
	labelSalesStartDate: 'Fecha comienzo de venta',
	labelSalesEndDate: 'Fecha fin de venta',
	labelEventDatail: 'Detalles del evento',
	labelMinutesForExpiration: 'Tiempo de reserva (mins)',
	eventStartDateRequired: 'Fecha de comienzo de evento requerida',
	eventEndDateRequired: 'Fecha del fin del evento requerida',
	salesStartDateRequired: 'Fecha comienzo de venta requerida',
	salesEndDateRequired: 'Fecha fin de venta requerida',
	eventRequired: 'Evento requerido',
	minutesForExpirationRequired: 'Minutos para expiración requerido',
	eventDetailRequired: 'Detalles del evento requerido',
	labelAdd: 'Agregar',
	labelAddSeat: 'Añadir asientos',
	sponsorRequired: 'Patrocinador Requerido',
	labelAddDoor: 'Añadir puerta',
	labelDoor: 'Puertas',
	labelDoorName: 'Nombre de puerta',
	labelDoorAvailability: 'Disponibilidad de Puerta',
	doorNameRequired: 'Nombre de puerta requerido',
	labelDelete: 'Eliminar',
	labelCurrentImage: 'Imagen actual',
	labelEventDate: 'Fecha del evento',
	labelSaleDate: 'Fecha de venta',
	labelActiveToSell: 'Activo para venta',
	labelActive: 'Activo',
	labelInactive: 'Inactivo',
	labelEmailMatch: 'Correos electrónicos no coinciden',
	labelVATInformation: 'Información de facturación',
	labelVATwho: 'Nombre o Razón Social',
	labelVATemail: 'Email de facturación',
	labelVATnumber: 'Cédula Física o Jurídica',
	labelSeeRecommended: 'Ver Recomendados',
	labelFunctionNum: 'Fecha ',
	labelGate: 'Puerta',
	labelTicketSoon: 'Tiquetes disponibles pronto',
	labelFindBestEvents: 'Encuentre aquí los mejores eventos',
	labelBannerText: '¡Viva la experiencia!',
	labelLoginText: '¡Viva la experiencia!',
	About1:
		'En Special Ticket abrimos nuestras puertas en el año 2003, desde entonces, nos hemos dedicado a la venta y distribución de entradas para todo tipo de eventos (musicales, deportivos, teatrales, ayuda social, entre otros.) Nos enorgullece ser una marca y empresa líder en el mundo del espectáculo costarricense.',
	About2:
		'Hemos sido pioneros en la venta en línea, acompañando a nuestros clientes a más de 300 eventos, y brindando un servicio al cliente excepcional.',
	About3:
		'Somos profesionales y apasionados. Buscando siempre nuevas maneras de innovar y potenciar el valor de nuestros clientes. ',
	WereSTS: 'Somos Special Ticket',
	labelErrorPageTitle: '¡Ups!',
	labelErrorPageText1: 'Ha ocurrido un error.',
	labelErrorPageText2: 'Por favor intente de nuevo.',
	labelTryAgain: 'Volver a intentar',
	labelUnableToBuyError:
		'No fue posible realizar la compra, por favor intente de nuevo.',
	labelSeatsTakenError: 'Algunos tiquetes ya no se encuentran disponibles.',
	labelTo: 'al',
	'terms-and-conditions': 'Términos & Condiciones',
	'terms-and-conditions-ferry': 'Términos & Condiciones Coonatramar',
	'Terms&ConditionsDesc': 'Encuentre aquí los Términos & Condiciones.',
	'privacy-policy': 'Política de privacidad',
	PrivacyPolicyDesc: 'Encuentre aquí la Política de privacidad.',
	EventTerms: 'Términos & condiciones para este evento',
	labelMaxExceededError: 'El límite de asientos es ',
	labelZones: 'Zonas & Precios',
	labelConfirm: 'Confirmar',
	labelConfirmToContunue: 'Confirmación requerida',
	labelWantToContinue: '¿Desea continuar con esta acción?',
	'change-password': 'Cambiar Contraseña',
	ChangePasswordDesc: 'Modifique su contraseña actual.',
	labelAPassword: 'Contraseña actual',
	labelCompleteForm: 'Debe completar el formulario',
	successChangePassword: 'Contraseña actualizada exitosamente',
	passChar: 'Un caracter especial.',
	passNum: 'Un número.',
	passCapital: 'Una letra mayúscula.',
	passLower: 'Una letra minúscula.',
	passMatch: 'Contraseñas coinciden.',
	oldpasswordnotmatch: 'Contraseña actual no coincide.',
	editVenue: 'Editar Lugar',
	'Venue Already Exists': 'Lugar ya se encuentra creado',
	'Venues Deleted successfully': 'Lugares eliminados exitosamente',
	'Categories Deleted successfully': 'Categorias eliminadas exitosamente',
	'Events Deleted successfully': 'Eventos eliminados exitosamente',
	labelSeatZones: 'Zonas',
	labelSeatZoneDetail: 'Descripción de la zona',
	blockColor: 'Color del bloque',
	prices: 'Precios',
	pricesList: 'Lista de precios',
	taxesList: 'Lista de impuestos',
	labelTaxName: 'Nombre del impuesto',
	labelTaxValue: 'Valor del impuesto',
	labelFlat: 'Fijo',
	labelPercent: 'Porcentaje',
	FielRequired: 'Campo requerido',
	labelTaxType: 'Tipo de Impuesto',
	labelPriceDesc: 'Descripción del precio',
	labelPriceAmount: 'Monto a cobrar',
	labelPriceFee: 'Cargo por servicio',
	labelAddTax: 'Agregar Impuesto',
	labelEditTax: 'Editar Impuesto',
	selectedTaxes: 'Impuestos seleccionados',
	labelAmount: 'Monto',
	PriceDetail: 'Desgloce de precio',
	ProductorPrice: 'Precio de producción',
	ProductorTotal: 'Total producción',
	labelPriceFeeTax: 'Impuesto al Cargo por Servicio',
	labelFinalAmount: 'Monto Final',
	labelNoTaxAmount: 'Monto sin impuestos',
	labelAddPrice: 'Agregar precio',
	labelEditPrice: 'Editar precio',
	NoUpdateTheZone: 'No fue posible editar la zona',
	SeatZoneExits: 'Zona ya existe',
	labelEdit: 'Editar',
	labelEditSeats: 'Ver Asientos',
	labelSeatRow: 'Fila de asiento',
	labelNumerate: 'Numeración ordenada',
	labelCheckerAssigned: 'Tiene chequeador(es) asignado(s)',
	labelSeats: 'Asientos',
	labelReserveTicket: 'Reservar tiquetes',
	labelNoRecommendations: 'No hay recomendaciones',
	labelCheckers: 'Chequeadores',
	labelCheckerName: 'Nombre del chequeador',
	labelChecker: 'Chequeador',
	completeRecaptcha: 'Debe completar el recaptcha.',
	recaptchaError: 'Hubo un error validando recaptcha.',
	labelGateName: 'Nombre de la puerta',
	labelAssignChecker: 'Asignar Chequeador(es)',
	mustSelectChecker: 'Debe seleccionar al menos un chequeador',
	labelCountFrom: 'Empezando desde',
	labelUserMaxQty: 'Tiquetes por usuario',
	labelCabys: 'Código cabys',
	eventEndDateMustBeGreater:
		'La fecha de finalización del evento debe ser mayor a la de inicio',
	eventSaleDateMustBeGreater:
		'La fecha de finalización de venta del evento debe ser mayor a la de inicio',
	labelZoneOp: 'Operaciones sobre zonas',
	labelSellQty: 'Cantidad en Venta',
	labelAssignedPrices: 'Tiene precio asignado',
	labelDontHave: 'No se encuentra asignado',
	labelHasAssigned: 'Se encuentra asignado',
	labelAssignDoor: 'Asignar puerta',
	labelAssignCoupons: 'Asignar cupones',
	labelAssignPrice: 'Asignar precio',
	labelCoupons: 'Cupones',
	labelNoChanges: 'Debe cambiar para poder editar',
	mustSelectGate: 'Debe Seleccionar una puerta',
	mustSelectPrice: 'Debe Seleccionar un precio',
	roleList: 'Lista de roles',
	roleName: 'Nombre del rol',
	Dashboard: 'Dashboard',
	editSeats: 'Editar Asientos',
	editEvent: 'Editar Evento',
	editEventDetail: 'Editar Fecha de Evento',
	labelPermissions: 'Permisos',
	accessToAllReports: 'Visualización de todos los eventos en reportes',
	userLookup: 'Busqueda',
	UserNoExists: 'Usuario no existe',
	userData: 'Información del usuario',
	labelStatus: 'Estado',
	upgradeRole: 'Cambio de rol',
	Inactivate: 'Inactivar ',
	Activate: 'Activar',
	reports: 'Reportes',
	labelAssignBIN: 'Asignar bines',
	labelBinsHere: 'Agregue sus bines aquí',
	labelBins: 'Bines',
	binInvalid: 'Al menos un bin es inválido',
	SellWithBins: 'Venta con bines',
	cardTransactions: 'Transacciones',
	endDateMustBeGreater:
		'La fecha de finalización debe ser mayor a la fecha de inicio',
	labelCard: 'Tarjeta Usada',
	labelFunctionDate: 'Fecha de la función',
	labelQuantityTickets: 'Cantidad de tiqutes',
	labelTransProccessedDate: 'Fecha de procesamiento',
	labelTransactionType: 'Tipo de Transacción',
	labelVenta: 'Venta',
	labelCortesia: 'Cortesía',
	labelTransactionReport: 'Reporte de transacciones',
	labelTransfers: 'Transferencias',
	labelTransfersReport: 'Reporte de transferencias',
	NoData: 'No existe información para los filtros indicados',
	availableSeats: 'Disponibilidad',
	clean: 'Limpiar',
	MustSelectEvent: 'Debe seleccionar un evento',
	labelBlocked: 'Bloqueados',
	labelGifted: 'Cortesias',
	labelReservedT: 'Reservados',
	labelSold: 'Vendidos',
	labelSales: 'Ventas',
	labelAvailabilityReport: 'Reporte de Disponibilidad',
	consolidated: 'Consolidado de ventas',
	labelSelectByZone: 'Seleccionar por zona',
	labelColor: 'Color',
	labelName: 'Nombre',
	labelDetail: 'Detalle',
	labelBlock: 'Bloquear',
	labelUnblock: 'Desbloquear',
	labelGift: 'Cortesías',
	labelSell: 'Vender',
	labelGiftSuccess: '¡Cortesía enviada exitosamente!',
	labelBlockSuccess: '¡Asientos bloqueados exitosamente!',
	labelUnblockSuccess: '¡Asientos desbloqueados exitosamente!',
	labelSeatsConflict: 'Verifique los asientos seleccionados.',
	serverError: 'Hubo un error en el servidor.',
	unexpectedError:
		'¡Ups! Hubo un error inesperado. Por favor intente de nuevo.',
	labelSeeDetail: 'Ver Detalle',
	labelTransactionDate: 'Fecha de Transacción',
	labelTransferDate: 'Fecha de Transferencia',
	labelReciever: 'Destinatario',
	labelAssignEvent: 'Asignar Evento',
	labelConsolidatedSales: 'Ventas Consolidadas',
	labelConsolidatedSalesReport: 'Reporte de Ventas Consolidadas',
	labelConsolidatedSalesChart: 'Gráfico de Ventas Consolidadas',
	labelPrice: 'Precio',
	labelServiceFee: 'Cargo por Servicio',
	labelServiceFeeIv: 'Cargo por Servicio + IV',
	operations: 'Operaciones',
	cancelSales: 'Anular Ventas/Cortesías',
	labelCancelS: 'Anular',
	labelCancelationReport: 'Anulaciones',
	labelCanceledBy: 'Responsable de anulación',
	labelCanceledTime: 'Fecha de anulación',
	mustLoginAgain: 'Debe iniciar sesión para acceder a este recurso',
	seatManagement: 'Administración de asientos',
	labelDetails: 'Detalles',
	labelDates: 'Fechas del reporte',
	onGoingEvents: 'Sus próximos eventos.',
	pricesTypes: 'Tipos de precios',
	discountsList: 'Lista de descuentos',
	labelAddDiscount: 'Agregar descuento',
	labelValue: 'Valor',
	labelType: 'Tipo',
	labelUse: 'Uso',
	labelSingleTicket: 'Singular',
	labelMultipleTicket: 'Transacción',
	labelPriceTypeName: 'Nombre del tipo de precio',
	labelEditPT: 'Editar tipo de precio',
	labelAddPT: 'Agregar tipo de precio',
	labelPriceType: 'Tipo de precio',
	labelCurrency: 'Moneda',
	labelEditPriceR:
		'Al editar el precio, si no existen tiquetes vendidos con este precio se editará completamente, de lo contrario la edición será parcial',
	chooseToDoSeats: 'Escoger que hacer con los asientos.',
	seatManagementRoles: 'Permisos de administración de asientos',
	blockSeats: 'Bloquear / desbloquear asientos',
	giftSeats: 'Generar cortesías',
	sellSeats: 'Vender tiquetes',
	assignedPrices: 'precios asignados',
	assignedPrice: 'Precio asignado',
	assignPriceType: 'Asignar tipo de precio',
	labelMakeAvailable: 'Disponibilizar asientos',
	labelQtyToM: 'Cantidad a disponibilizar',
	labelQtyInfo: 'Al dejar en 0 la cantidad total faltante se disponibilizará',
	terms1:
		'El uso del sitio specialticket.net, propiedad de Special Ticket Sale BV, S.A., está regido por los términos y condiciones que a continuación se describen. Su uso indica la plena e incondicionada aceptación de los mismos. Al hacer click en la casilla de términos y condiciones de este sitio web usted acepta todos los términos y condiciones detallados a continuación. Special Ticket Sale BV, S.A., se reserva el derecho de realizar cambios en el sitio y en los Términos y Condiciones en cualquier momento sin preaviso.',
	terms2:
		'Special Ticket Sale BV, S.A. es un prestador de servicios, y por lo tanto no tiene responsabilidad por ninguna situación relacionada con el evento.',
	terms3:
		'Una vez ingresado a la página web ya indicada, el comprador acepta las condiciones de compra que son:',
	terms4:
		'Antes de realizar la compra por favor verificar el evento, fecha, zona, número de asientos que desea adquirir, así como que su correo electrónico esté correcto, ya que una vez finalizada la compra no están autorizados cambios, reembolsos ni devoluciones. Para el caso de eventos cancelados por causas ajenas a SpecialTicket Sale BV SA, el adquiriente de esta entrada se obliga a realizar los reclamos de devolución de dinero al ORGANIZADOR O RESPONSABLE DEL EVENTO, que será exclusivamente el responsable de la devolución sin ningún tipo de reajuste, liberando de cualquier tipo de responsabilidad a Special Ticket Sale BV, S.A.',
	terms5:
		'EL ORGANIZADOR O RESPONSABLE DEL EVENTO se reserva el derecho de admisión, así como el derecho de no autorizar el ingreso de alimentos, bebidas, cámaras fotográficas o filmadoras profesionales. No se admite el ingreso de armas de fuego ni punzocortantes.',
	terms6:
		'Special Ticket Sale BV, S.A y EL ORGANIZADOR O RESPONSABLE DEL EVENTO no se responsabilizan por eventuales falsificaciones, o el uso incorrecto de la entrada digital mediante reenvíos del código QR.',
	terms7:
		'Special Ticket Sale BV, S.A. se reserva el derecho de solicitar al cliente la documentación personal necesaria para validar su ingreso (cédula nacional, de residencia o pasaporte, tarjeta con la que se realizó la transacción,correo electrónico  con la compra).',
	terms8:
		'Special Ticket Sale BV, S.A., queda autorizada para utilizar los datos personales de los compradores de entradas para hacer ofertas comerciales, y prospectación de sus productos y para ellos podrá ceder los referidos datos a sus empresas afiliadas, subsidiarias y relacionadas para que estas los usen para fines comerciales. Special Ticket Sales BV, S.A., podrá entregar los datos personales del cliente al ORGANIZADOR O RESPONSABLE DEL EVENTO.',
	policyTitle1: 'Recolección de la información',
	policyTitle2: 'Uso de la información',
	policyTitle3: 'Privacidad del comercio electrónico',
	policyTitle4: 'Divulgación a terceros',
	policyTitle5: 'Protección de la información.',
	policyTitle6: 'Desinscribirse.',
	policyTitle7: 'Consentimiento.',
	policy1:
		'Recogemos información sobre usted cuando se registra en nuestro sitio, ingresa a su cuenta, realiza una compra y/o cuando cierra su sesión. La información recolectada incluye su nombre completo, número de documento de identidad, dirección de correo electrónico, número de teléfono y los primeros seis dígitos y últimos cuatro dígitos de su tarjeta de crédito/débito.',
	policy2:
		'Cualquier información que recolectamos sobre usted puede ser utilizada para:',
	policy21:
		'Personalizar su experiencia y responder a sus necesidades individuales.',
	policy22: 'Proporcionar contenido publicitario personalizado.',
	policy23: 'Mejorar nuestro sitio web.',
	policy24: 'Mejorar el servicio al consumidor y sus necesidades de soporte.',
	policy25: 'Contactarlo por correo electrónico y/o por teléfono.',
	policy3:
		'Nosotros somos los únicos propietarios de la información recolectada en este sitio. Su información de identificación personal no será vendida, intercambiada, transferida ni     proporcionada a ninguna empresa de terceros por ninguna razón, sin su consentimiento, a menos que sea necesario para cumplir con una solicitud y/o transacción, por ejemplo, para enviar un pedido.',
	policy4:
		'Nosotros no vendemos, intercambiamos, ni transferimos de ningún otro modo a terceros externos su información de identificación personal. Esto no incluye terceros confiables que nos asisten para operar nuestro sitio web o llevar a cabo nuestro negocio, siempre que las partes acepten mantener esta información confidencial. Creemos que es necesario compartir información para investigar, prevenir o tomar medidas respecto a actividades ilegales, sospechas de fraude, situaciones que impliquen amenazas potenciales a la seguridad física de cualquier persona, violaciones de nuestros términos de uso, o que de otra manera sea requerido por la ley.',
	policy5:
		'Implementamos distintas medidas de seguridad para mantener la seguridad de su información personal. Utilizamos encriptación de avanzada para proteger información confidencial transmitida en línea. Solo los empleados que necesitan realizar una tarea específica (por ejemplo, la facturación o atención al cliente) tienen acceso a la información de identificación personal. Las computadoras/servidores usados para almacenar la información de identificación personal están guardados en un entorno seguro.',
	policy6:
		'Utilizamos la dirección de correo electrónico que usted proporcionó para enviarle información y actualizaciones relacionadas con su compra, noticias ocasionales de la empresa, información de eventos, comprobantes de compra, entre otros. Si en algún momento usted quisiera desinscribirse para no recibir más correos electrónicos futuros, favor enviarnos un correo a la siguiente dirección electrónica:',
	policy7:
		'Al utilizar nuestro sitio, estando usted de acuerdo con la Política de Privacidad aquí consignada, nos autoriza para el siguiente uso de su información: a) para el fin mismo por lo cual se ha suministrado; b) para considerarlo dentro de nuestras estadísticas de tráfico, incrementando así nuestra oferta publicitaria y de mercado; c) para orientar mejor los servicios aquí ofrecidos y valorarlos a su criterio; d) para enviar e-mails con nuestros boletines, responder inquietudes o comentarios, y e) para ser usado por nuestros socios comerciales y/o contratistas con el único fin de enviar informaciones o promociones de interés del usuario.',
	mustAcceptTerms: 'Debe aceptar los términos y condiciones.',
	labelAcept: 'Aceptar',
	labelPayMethod: 'Método de pago',
	labelDataphone: 'Datáfono',
	labelCash: 'Efectivo',
	labelAddSzInfo:
		'Al agregar una zona de asientos esta se agregará automáticamente a aquellos eventos en el futuro que aún no estén activos para la venta.',
	labelPriceDetailsR: 'Desglose de precio',
	labelOnline: 'En línea',
	labelSignUpUser: 'Registrar usuario',
	labelAuthCode: 'Código de autorización',
	labelStatusId1: 'Disponible',
	labelStatusId2: 'Bloqueado',
	labelStatusId3: 'Reservado',
	labelBlockIdentifier: 'Identificador de bloqueo',
	labelPayType: 'Forma de Pago',
	labelGivenBy: 'Vendedor',
	datafono: 'Datafono físico',
	efectivo: 'Efectivo',
	onlineb: 'En línea boletería',
	online: 'En línea',
	labelTotalServiceFee: 'Total Cargo por servicio',
	labelTotalServiceFeeIv: 'Total Cargo por servicio + IV',
	labelConsolidatedPieChart: 'Cantidad y ventas por forma de pago',
	sellPromos: 'Vender promocionales',
	labelPromoSuccess: 'Promocional vendida exitosamente',
	labelPromo: 'Promocionales',
	Sales: 'Ventas',
	Quantity: 'Cantidad',
	ServiceFee: 'Cargo por servicio',
	labelTotal: 'Total',
	SalesType: 'Ventas totales por tipo',
	labelPrint: 'Imprimir tiquetes',
	excededQuantityCancel: 'La cantidad a anular no debe ser mayor a la total',
	doTotalCancel: 'Intente de nuevo con anulación total',
	CompleteCancel: 'Anulación Completa',
	PartialCancel: 'Anulación Parcial',
	FreeUpSeat: 'Liberar',
	LockUpSeat: 'Bloquear',
	CancelType: 'Tipo de Anulación',
	labelScansR: 'Reporte de Escaneos',
	labelScansRH: 'Escaneos por hora',
	lblInSale: 'A la venta',
	lblSold: 'Vendidas',
	lblGenerated: 'Generadas',
	lblScanned: 'Escaneadas',
	labelScans: 'Escaneos',
	notRegisteredAlert:
		'Este usuario no se encuentra registrado en specialticket.net, un email será enviado para que se registre y acepte los tiquetes.',
	labelSource: 'Origen',
	cancelTransfer: 'Cancelar transferencia',
	confirmTransfer: 'Confirmar Transferencia',
	errorModifyingStaging: 'Hubo un error modificando el tiquete en proceso.',
	labelAssign: 'Asignar',
	labelCode: 'Código',
	labelBuyer: 'Comprador',
	labelOwnerId: 'Cédula del dueño',
	labelOwnerName: 'Nombre del dueño',
	labelOwnerEmail: 'Correo del dueño',
	labelTransactionId: 'ID de transacción',
	'payment-methods': 'Métodos de Pago',
	PaymentMethodsDesc: 'Mantenimiento de sus métodos de pago.',
	legal: 'Legal',
	purchases: 'Compras',
	seeTickets: 'Ver tiquetes',
	myTicketsDescription1: '¡',
	myTicketsDescription2: 'Descargue sus códigos QR',
	myTicketsDescription3: ' o ',
	myTicketsDescription4: 'transfiera sus tiquetes',
	myTicketsDescription5: ' a otros usuarios!',
	useSafari:
		'Debe utilizar Safari para  descargar sus tiquetes al Apple Wallet.',
	noAvailableTickets:
		'No tiene tiquetes disponibles para ningún evento en este momento.',
	all: 'Todos',
	underConstruction: 'En construcción',
	from: 'De',
	sentTo: 'Enviado a',
	myTransfersDescription1:
		'¡Aquí puede ver sus tiquetes enviados y recibidos! Si desea realizar una transferencia diríjase a ',
	myTransfersDescription2: 'mis tiquetes',
	recieved: 'Recibido',
	sent: 'Enviado',
	staging: 'Pendientes',
	completed: 'Completadas',
	cancel: 'Cancelar',
	accept: 'Aceptar',
	transferTickets: 'Transferir tiquetes',
	transferTicketsTo: 'Elija un destinatario para continuar',
	transferTicketsEmail: 'Digite un correo',
	RecieverDoesntExist: 'Usuario no registrado',
	aboutToTransfer1: 'Está por transferir a ',
	aboutToTransfer2:
		'. Puede cancelar la transferencia en cualquier momento si el destinatario no ha aceptado los tiquetes aún.',
	aboutToTransfer3:
		'Los tiquetes transferidos aparecerán en la cuenta de SpecialTicket asociada al correo de destino. ',
	aboutToTransfer4:
		'Por su seguridad, en ningún momento los códigos QR se enviarán por correo',
	aboutToTransfer5:
		'Una vez realizada la transferencia, cualquier código QR descargado o generado previo a la transferencia, ',
	aboutToTransfer6: 'NO ES VALIDO',
	aboutToTransferToUnregisteredUser:
		'El destinatario deberá registrarse en SpecialTicket, y luego aceptar la transferencia en Mis Transferencias.',
	transfering: 'Transfiriendo...',
	transferSuccess: '¡Transferencia exitosa!',
	transferedOneTo: 'tiquete transferido a ',
	transferedManyTo: 'tiquetes transferidos a ',
	transferError: '¡Transferencia fallida!',
	transferErrorMessage:
		'La transferencia ha fallado, por favor intente de nuevo.',
	aboutToAcceptTransfer: '¿Desea aceptar esta transferencia de ',
	aboutToCancelTransfer: '¿Desea cancelar esta transferencia a ',
	yesAccept: 'Sí, aceptar',
	yesCancel: 'Sí, cancelar',
	acceptTransferSuccess: '¡Transferencia aceptada exitosamente!',
	cancelTransferSuccess: '¡Transferencia cancelada exitosamente!',
	acceptTransferError:
		'¡La transferencia no pudo ser aceptada! Intente de nuevo.',
	cancelTransferError:
		'¡La transferencia no pudo ser cancelada! Intente de nuevo.',
	editProfileDesc1:
		'Aquí puede ver y editar la información de su perfil. Para cambiar su ',
	editProfileDesc2: 'contraseña ',
	editProfileDesc3: 'diríjase a ',
	editProfileDesc4: 'cambiar contraseña',
	birthdate: 'Nacimiento',
	name: 'Nombre',
	lastname: 'Apellido',
	id: 'Identificación',
	gender: 'Género',
	country: 'País',
	state: 'Región',
	billing: 'Facturación',
	billingDesc:
		'Por defecto, su facturación irá al correo de su cuenta de SpecialTicket.net. Aquí puede editar su información de facturación.',
	billingName: 'Nombre o razón social',
	billingVAT: 'Cédula Física o Jurídica',
	requiredField: 'Campo obligatorio',
	invalidPhone: 'Teléfono inválido',
	weAreSpecialTicket: '¡Somos Special Ticket!',
	feature1Title: 'Transferencias Seguras',
	feature1Body:
		'Transfiera sus entradas de forma segura entre usuarios dentro de nuestra página web. Olvídese de perder sus entradas o lidiar con boletos de papel poco confiables.',
	feature2Title: 'Entradas 100% Digitales',
	feature2Body:
		'Todas sus entradas se almacenan de manera segura en su cuenta digital. Acceda y gestione sus entradas de manera conveniente en cualquier momento y lugar.',
	feature3Title: 'Mapa Interactivo',
	feature3Body:
		'Explore nuestros mapas interactivos de eventos y vea exactamente dónde estará sentado. Obtenga una vista detallada de la disposición del lugar, asegurando una excelente experiencia en el evento.',
	unregisteredUser: 'No se encontró un usuario registrado con este correo.',
	acceptTerms: 'Acepto los Términos y Condiciones',
	available: 'Disponibles',
	blocked: 'Bloqueados',
	succesfulTransaction: '¡Transacción exitosa!',
	declinedTransaction: '¡Transacción declinada!',
	canceledTransaction: '¡Transacción cancelada!',
	promo: 'Promocional',
	sale: 'Venta',
	gift: 'Cortesía',
	declined: 'Declinada',
	canceled: 'Cancelada',
	currentPassword: 'Contraseña actual',
	newPassword: 'Contraseña nueva',
	newPasswordConfirm: 'Confirme la contraseña nueva',
	availableDates: 'Fechas disponibles',
	noAvailableEvents:
		'¡Lo sentimos! No hay eventos disponibles en cartelera en este momento.',
	loadingVenue: 'Cargando mapa, por favor espere...',
	noRows: 'No hay filas.',
	noResults: 'No hay resultados.',
	artist: 'Artista',
	detail: 'Detalle',
	category: 'Categoría',
	image: 'Imagen',
	producer: 'Productor',
	imageMustBeJpg: 'La imagen debe ser en formato .jpg',
	imageTooBig: 'La imagen debe ser menor a 1MB',
	eventImageRatio: 'La imagen debe tener una proporción de 5/3',
	login: 'Ingreso',
	forgotPasswordDesc:
		'No se preocupe, le enviaremos un enlace de restablecimiento de contraseña.',
	forgotPasswordError: 'No fue posible enviar el correo. Intente de nuevo.',
	linkSent: '¡Enlace enviado!',
	resendLink:
		'Si no ha recibido el enlace en 2 minutos, puede solicitarlo de nuevo.',
	notFoundTitle: '¿Perdido entre la multitud?',
	notFoundText:
		'¡Ups! Parece que ha tomado un camino equivocado y se ha perdido. No se preocupe; aquí encontrará diversas formas de regresar al centro del escenario. Eche un vistazo a algunos de nuestros próximos eventos para volver a estar en sintonía.',
	backHome: 'Regresar al inicio',
	errorTitle: '¡Ups, Algo Salió Mal!',
	errorText:
		'Parece que ha ocurrido algo inesperado en nuestra página. Nuestro equipo ya está en ello, trabajando diligentemente para resolver el problema y poner las cosas en orden. Mientras tanto, puedes explorar otras partes de nuestro sitio web o volver más tarde. Pedimos disculpas por cualquier inconveniente.',
	resetPassword: 'Reinicie su contraseña',
	resetPasswordDesc: 'Establezca su nueva contraseña',
	invalidLink: 'Enlace inválido',
	requestLinkAgain:
		'¡Lo sentimos! El enlace no es válido, por favor solicítelo nuevamente.',
	passwordReset: 'Contraseña reestablecida',
	unableToResetPassword: 'No fue posible reestablecer la contraseña.',
	samePassword: 'La contraseña no puede ser la misma que la actual.',
	invalidPassword: 'Contraseña inválida',
	downloadQR: 'Descargar',
	ticketsDownloadSuccess: '¡Tiquetes descargados!',
	ticketsDownloadError: 'No fue posible descargar los tiquetes',
	forbiddenTitle: '¡Las puertas del evento están cerradas de momento!',
	forbiddenDesc:
		'La puerta a este evento está cerrada por ahora. Pero, no se preocupe, hay muchas otras puertas abiertas a eventos increíbles.',
	acquireTickets1: 'Adquiera sus',
	acquireTickets2: 'tiquetes',
	understood: 'Entendido',
	selectSeatsTooltip:
		'Haga click en el mapa interactivo para seleccionar sus entradas.',
	expiredSession: 'Su sesión ha expirado. Por favor ingrese de nuevo',
	closestEvents: 'Los Más Cercanos',
	securingTickets: 'Asegurando sus tiquetes, por favor espere...',
	downloadTickets: 'Descargue las entradas seleccionadas',
	orTransferThem: 'o transfiéralas a otro usuario.',
	productsDialogTitle: '¡ACOMPAÑE SU EXPERIENCIA!',
	productsDialogSubtitle:
		'Eche un vistazo a los productos disponibles para este evento.',
	addToCart: 'Agregar al carrito',
	loginToContinue: 'Por favor inicie sesión para continuar.',
	clickHereToSeeTickets: 'Haga click aquí para ver sus tiquetes',
	seeProducts: 'Ver Productos',
	transactionNotFound: 'No fue posible ubicar la transacción ',
	noItemsInCart: 'El carrito está vacío.',
	unableToLoadEvent: 'No fue posible cargar el evento. Intente de nuevo.',
	youHavePendingTransfers1: 'Tiene transferencias pendientes. Diríjase a ',
	youHavePendingTransfers2: 'mis transferencias',
	youHavePendingTransfers3: ' para aceptarlas.',
	continue: 'Continuar',
	useRegularBrowser:
		'Para descargar sus entradas por favor abra nuestra página desde su navegador de preferencia.',
	noTransfersAllowed: 'Este evento no permite la transferencia de tiquetes.',
	free: 'Gratis',
	'Unavailable capacity': 'No hay fechas disponibles para este mes.',
	'Capacity Overflowed': 'Los tiquetes seleccionados ya no están disponibles.',
	'Capacity By Quantity Overflowed':
		'Los tiquetes seleccionados ya no están disponibles.',
	selectDate: 'Seleccione la fecha',
	selectDateDesc:
		'Busque la fecha deseada para ver la disponibilidad de horario.',
	selectTime: 'Seleccione el horario',
	selectTimeDesc: 'Seleccione el horario al que desea asistir.',
	time: 'Horario',
	selectTickets: 'Escoja sus tiquetes',
	selectTicketsDesc:
		'Seleccione la cantidad de tiquetes que requiere para cada una de las opciones.',
	seeAvailability: 'Ver Disponibilidad',
	selectDateTime: 'Seleccione fecha y hora',
	step: 'Paso',
	sailToAdventure: '¡Navegue hacia la aventura!',
	reserveNow: 'Reserve su Ferry Ahora',
	licensePlate: 'Placa',
	typeLicensePlates:
		'Ingrese los números de placa para cada uno de los vehículos.',
	allFieldsMustBeFilled: 'Debe llenar todos los campos para continuar.',
	oneDriverPerVehicle:
		'Por cada vehículo debe seleccionar un tiquete de "Adulto" o "Adulto Mayor".',
	heavyVehicleRestrictions:
		'Buses y vehículos de carga 2+ ejes están sujetos a disponibilidad según marea.',
	'Something has ocurred in the server':
		'Ha ocurrido un error. Intente de nuevo.',
	origin: 'Origen',
	destination: 'Destino',
	changeDestination: 'Cambiar destino',
	orderConfirmation: 'Confirmación de Compra',
	route: 'Ruta',
	instructions: 'Por favor tome en cuenta las siguientes indicaciones:',
	instruction1: 'Llegue a la terminal 1 hora antes de la hora de zarpe.',
	instruction2:
		'Al llegar a la fila, busque al compañero encargado para que lo ubiquen en la fila diferenciada de tiquetes en línea.',
	instruction3:
		'Al llegar temprano, aborde de primero para permitir una carga de vehículos ágil.',
	instruction4:
		'Vehículos de carga (3,4 y 5 ejes) sujetos a condiciones de marea. Recomendamos consultar previo a la compra de su tiquete.',
	instruction5: 'Si su vehículo tiene placa CL debe seleccionar CARGA LIVIANA en el tipo de vehículo.',
};

export default phrases;
