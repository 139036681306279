import { theme } from '@/theme/theme';

export default function useStyles() {
	return {
		button: {
			color: theme.palette.secondary.contrastText,
			backgroundColor: theme.palette.glass.main,
			WebkitBackdropFilter: 'blur(5px)',
			backdropFilter: 'blur(5px)',
			borderRadius: '0.3rem',
			fontSize: '1.25rem',
			paddingInline: theme.spacing(2),
		},
		list: {
			zIndex: 1000,
			maxHeight: 300,
			overflowY: 'scroll',
			color: theme.palette.secondary.contrastText,
			backgroundColor: theme.palette.secondary.main,
			boxShadow: '10px 10px 39px 0px rgba(0, 0, 0, 0.56)',
			webkitBoxShadow: '10px 10px 39px 0px rgba(0, 0, 0, 0.56)',
			mozBoxShadow: '10px 10px 39px 0px rgba(0, 0, 0, 0.56)',
			borderRadius: '0.3rem',
			'*': {
				color: theme.palette.secondary.contrastText,
				width: '100%',
				':hover': {
					color: theme.palette.primary.main,
				},
			},
		},
		menu: {
			minWidth: 200,
			'& .MuiMenuItem-root:nth-of-type(odd)': {
				backgroundColor: 'rgba(255, 255, 255, 0.03)', // Adjust the color as needed
			},
			'& .MuiMenuItem-root:hover': {
				backgroundColor: 'rgba(0, 0, 0, 0.3)',
				color: theme.palette.primary.main, // Adjust the color as needed
			},
		},
	};
}
