import { useTheme } from '@mui/material/styles';

export default function useStyles() {
	const theme = useTheme();

	return {
		paper: {
			background: theme.palette.secondary.main,
			paddingInline: { xs: 0, md: 8 },
		},
		actions: {
			p: 4,
			justifyContent: { xs: 'space-between', md: 'end' },
			gap: 4,
		},
	};
}
