import { axiosInstance } from '..';
export const fetchTransferHistory = async (userId, token) => {
	const url = `/UserOrderHistory/UserTransferHistory?id=${userId}`;

	const header = {
		headers: { Authorization: `Bearer ${token}` },
	};

	const res = await axiosInstance.get(url, header);

	return res.data;
};
