import { useState, useEffect } from 'react';
import useStore from './useStore';
import Cookies from 'js-cookie';

const useAuth = () => {
	const { user, setUser, removeUser } = useStore();
	const [isAuth, setIsAuth] = useState(
		user !== null && Object.keys(user).length > 0,
	);
	const [isAdmin, setIsAdmin] = useState(user !== null && user.roleId !== 3);
	// For getting user information details from Local Storage
	useEffect(() => {
		setIsAuth(user !== null && Object.keys(user).length > 0);
	}, [user]);

	useEffect(() => {
		setIsAdmin(user !== null && user.roleId !== 3);
	}, [user]);

	const logout = () => {
		const options = {
			sameSite: 'Strict', // Set SameSite
			secure: true, // Set Secure to ensure the cookie is sent only over HTTPS
		};
		// Adjust domain based on environment
		if (
			process.env.NODE_ENV === 'production' ||
			import.meta.env.VITE_APP_ENV === 'PROD'
		) {
			options.domain = '.specialticket.net';
		}

		Cookies.remove('_Yqehnnmaso', options);
		removeUser();
	};

	const authorize = user => {
		if (user) {
			const options = {
				sameSite: 'Strict', // Set SameSite
				secure: true, // Set Secure to ensure the cookie is sent only over HTTPS
			};
			// Adjust domain based on environment
			if (
				process.env.NODE_ENV === 'production' ||
				import.meta.env.VITE_APP_ENV === 'PROD'
			) {
				options.domain = '.specialticket.net';
			}

			Cookies.set('_Yqehnnmaso', JSON.stringify(user.token), options);
			setUser(user);
		}
	};

	return { isAuth, isAdmin, authorize, logout };
};

export default useAuth;
