import { circularProgressClasses } from '@mui/material';
import { theme } from '@/theme/theme';

export default function useStyles() {
	return {
		background: {
			color: theme,
			zIndex: theme => theme.zIndex.drawer + 1000,
			background:
				'radial-gradient(circle at 0% 0%,rgba(10, 91, 129, 1) 0%,rgba(21, 17, 58, 1) 57%,rgba(21, 17, 58, 1) 100%)',
		},
		logoTypography: {
			overflow: 'hidden',
		},
		logoDot: {
			position: 'relative',
			width: 54,
			height: 54,
			zIndex: 2,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		progressContainer: {
			position: 'absolute',
			zIndex: -1,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		progress: {
			color: theme.palette.primary.main,
			animationDuration: '700ms',
			[`& .${circularProgressClasses.circle}`]: {
				strokeLinecap: 'round',
			},
		},
	};
}
