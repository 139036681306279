import { useTheme } from '@mui/material';

export default function useStyles() {
	const theme = useTheme();
	return {
		container: {
			mt: { xs: 0, md: 12 },
			maxWidth: theme.breakpoints.values.xl - 100,
			width: '100%',
		},
		title: {
			fontWeight: 'bold',
			fontSize: { xs: '2rem', md: '3rem !important' },
			textTransform: 'uppercase',
		},
		subtitle: {
			fontWeight: 100,
		},
		iconButton: {
			display: { xs: 'none', md: 'block' },
			width: 40,
			height: 40,
			backgroundColor: theme.palette.primary.main,
			boxShadow: `4px 4px 12px 0px ${theme.palette.primary.light}`,
			':hover': {
				color: theme.palette.secondary.contrastText,
				background: theme.palette.secondary.light,
				boxShadow: `4px 4px 12px 0px ${theme.palette.secondary.light}`,
				transform: 'scale(1.04)',
				'*': {
					color: theme.palette.secondary.contrastText,
				},
			},
		},
	};
}
