import { useTheme } from '@mui/material';

export default function useStyles() {
	const theme = useTheme();
	return {
		alert: {
			fontWeight: 100,
			borderRadius: '1.3rem',
			padding: theme.spacing(3),
			minWidth: 321,
			background: theme.palette.glass.main,
			color: theme.palette.secondary.main,
			backdropFilter: 'blur(12px)',
			gap: theme.spacing(2),
			height: 'fit-content',
			'*': {
				fontWeight: 100,
				color: theme.palette.primary.main,
			},
		},
	};
}
