import { useTheme } from '@mui/material';

export default function useStyles() {
	const theme = useTheme();
	return {
		root: {},
		back: {
			mt: theme.spacing(2),
			height: 'min-content',
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
			boxShadow: `4px 4px 12px 0px ${theme.palette.primary.light}`,
			':hover': {
				color: theme.palette.secondary.contrastText,
				background: theme.palette.secondary.light,
				boxShadow: `4px 4px 12px 0px ${theme.palette.secondary.light}`,
			},
		},
	};
}
