import { useTheme } from '@mui/material';

export default function useStyles(variant) {
	const theme = useTheme();
	return {
		dialog: {
			p: { xs: 0, md: 2 },
			pb: 4,
			pt: 0,
			minHeight: { xs: '100vh', sm: 0 },
			minWidth: { xs: 'none', md: theme.breakpoints.values.sm },
			background: theme.palette.secondary.main,
			color: theme.palette.secondary.contrastText,
			borderRadius: { xs: 0, sm: theme.shape.borderRadius },
		},
		close: {
			width: 'fit-content',
			transition: 'all ease-in-out 100ms',
			borderRadius: '1.3rem',
			border: 'none',
			color: theme.palette.glass.main,
			':hover': {
				color: theme.palette.primary.main,
			},
		},
		order: {
			background: theme.palette.glass.main,
			borderRadius: '0.3rem',
			p: 2,
		},
		total: {
			fontSize: 24,
			fontWeight: 'bold',
		},
		description: {
			fontWeight: 100,
			fontSize: 12,
			color: theme.palette.primary.main,
		},
		confirm: {
			marginInline: 'auto',
			width: 330,
		},
		divider: {
			height: 1,
			borderRadius: '50%',
			background: theme.palette.glass.main,
		},
		listText: {
			fontWeight: 100,
			fontSize: 14,
		},
	};
}
