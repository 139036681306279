import { axiosInstance } from '..';
export const fetchAvailableEventDetails = async (
	eventId,
	seats,
	date,
	token,
	cancelToken,
) => {
	const url = `/BuyTicket/GetAvailableEventDetail`;

	const header = {
		headers: { Authorization: `Bearer ${token}` },
		cancelToken,
	};
	const res = await axiosInstance.post(
		url,
		{
			EventId: eventId,
			BuyTicketMultipleModels: seats,
			Date: date,
		},
		header,
	);
	return res.data;
};
