import { useTheme } from '@mui/material';

export default function useStyles() {
	const theme = useTheme();
	return {
		button: {
			':hover': {
				transform: 'none',
			},
		},
		flag: {
			borderRadius: '50%',
			boxShadow: theme.shadows[3],
		},
	};
}
