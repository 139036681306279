import { useTheme } from '@mui/material';

export default function useStyles() {
	const theme = useTheme();
	return {
		container: {
			paddingTop: theme.spacing(8),
			width: '100%',
		},
	};
}
