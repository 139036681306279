import { config } from '@/config';
import axios from 'axios';

export const fetchCustomerToken = async (userId, userToken) => {
	const url = `${config.payApi}/api/customertoken`;
	const res = await axios.post(
		url,
		{
			customerId: userId,
		},
		{ headers: { Authorization: `Bearer ${userToken}` } },
	);
	return res.data;
};
