import { useTheme } from '@mui/material';

export default function useStyles() {
	const theme = useTheme();
	return {
		container: {
			mt: 6,
			position: 'relative',
			overflow: 'hidden',
			pb: 12,
		},
		title: {
			width: '100%',
			fontWeight: 'bold',
			fontSize: '3.5rem !important',
			textTransform: 'uppercase',
		},
		subtitle: {
			width: '100%',
			fontWeight: 100,
		},
	};
}
