import { useTheme } from '@mui/material';

export default function useStyles() {
	const theme = useTheme();
	return {
		label: {
			fontWeight: 100,
		},
		value: {
			fontWeight: 900,
			color: theme.palette.primary.main,
			fontSize: { xs: 20, md: 24 },
			textTransform: 'uppercase',
		},
		divider: {
			background: theme.palette.glass.main,
			height: 54,
			width: 4,
			borderRadius: '0.3rem',
			display: { xs: 'none', sm: 'block' },
		},
	};
}
