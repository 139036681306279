import { useTheme } from '@mui/material/styles';

export default function useStyles() {
	const theme = useTheme();

	return {
		container: {
			width: '100%',
		},
		top: {
			paddingInline: { xs: 4, md: 16 },
			paddingBlock: { xs: 4, md: 8 },
			backgroundColor: theme.palette.secondary.main,
		},
		bottom: {
			backgroundColor: theme.palette.primary.main,
			p: 2,
		},
		content: { width: '100%', marginInline: 'auto' },
		title: {
			textTransform: 'uppercase',
			color: theme.palette.secondary.contrastText,
		},
		contacts: {
			'*': {
				color: theme.palette.secondary.contrastText,
			},
		},
		iconButton: {
			backgroundColor: theme.palette.primary.main,
			':hover': {
				color: theme.palette.secondary.contrastText,
				background: theme.palette.secondary.light,
				boxShadow: `4px 4px 12px 0px ${theme.palette.secondary.light}`,
				transform: 'scale(1.04)',
				'*': {
					color: theme.palette.secondary.contrastText,
				},
			},
		},
		copyright: {
			textAlign: 'center',
			[theme.breakpoints.down('sm')]: {
				fontSize: '0.8rem',
			},
		},
	};
}
