import { useTheme } from '@mui/material';

export default function useStyles() {
	const theme = useTheme();
	return {
		total: {
			fontSize: 24,
			fontWeight: 'bold',
		},
		alert: {
			fontWeight: 100,
			borderRadius: '0.6rem',
			paddingInline: theme.spacing(2),
			minWidth: 321,
			height: 'fit-content',
			display: 'flex',
			alignItems: 'center',
			'.MuiAlert-message': {
				fontWeight: 100,
				color: theme.palette.secondary.contrastText,
			},
			'.MuiAlert-icon': {
				background: theme.palette.error.light,
				color: theme.palette.error.main,
				width: 32,
				height: 32,
				p: 1,
				borderRadius: '0.3rem',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			},
		},
	};
}
